import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from 'react-router-dom';
import { Tooltip as Tol, OverlayTrigger } from "react-bootstrap";


export default function TableLIsteOR(props) {

    const navigate = useNavigate()
//   console.log("props", props.expoData)
    const [globalFilter, setGlobalFilter] = useState('');
    const dt = useRef(null);

    const cols = [
        { field: "num", header: "N°", sortable: true },
        { field: 'lib_obligation_reg', header: 'Libellé', sortable: true },
        { field: 'textes_ref', header: 'Références', sortable: true },
        { field: 'secteur_obligation', header: "Secteur d'obligation", sortable: true },
        // { field: 'type_obligation', header: "Type d'obligation", sortable: true },
        { field: 'Type_operateur', header: "Type d'acteurs", sortable: true },
        { field: 'poids', header: "Poids", sortable: true },
        { field: 'actions', header: 'Actions', sortable: false } // Nouvelle colonne pour les actions
    ];
    const colsExpo = [
        { field: "num", header: "N°", sortable: true },
        { field: 'secteur_obligation', header: "Secteur", sortable: true },
        { field: 'categorie', header: "Catégorie", sortable: true },
        { field: 'lib_obligation_reg', header: "libelle", sortable: true },
        { field: 'ref', header: "Textes réf", sortable: true },
        { field: 'article', header: "Articles", sortable: true },
        { field: 'Type_operateur', header: "Acteurs", sortable: true },
        { field: 'elt', header: "Eléménts de contrôle", sortable: true },
        { field: 'periode', header: "Périodicité", sortable: true },
        { field: 'src', header: "Source de vérification", sortable: true },
        { field: 'echeance', header: "Echéance regl.", sortable: true },
        { field: 'direction', header: "Direction en charge", sortable: true },
        { field: 'poids', header: "Poids", sortable: true },
       
    ];
   
    const exportColumns = colsExpo.map((col) => ({ title: col.header, dataKey: col.field }));

    useEffect(() => {
       
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    // const exportPdf = (donnee) => {
    //     console.log("donnee", donnee)
    //     import("jspdf").then((jsPDF) => {
    //         import("jspdf-autotable").then(() => {
    //             const doc = new jsPDF.default({ orientation: "landscape" }); // Spécifie le mode paysage
    
    //             const title = "Etat PNN"; // Titre de votre document
    //             const tableStartY = 20; // Position de départ du tableau
    //             let isFirstPage = true; // Indicateur pour la première page
    
    //             // Configuration du tableau
    //             const tableConfig = {
    //                 startY: tableStartY, // Position de départ du tableau
    //                 theme: "grid", // Thème du tableau
    //                 styles: {
    //                     overflow: "linebreak", // Gérer le renvoi à la ligne automatique
    //                 },
    //                 columnStyles: {
    //                     // Styles des colonnes
    //                     // Ajoutez les styles pour chaque colonne si nécessaire
    //                 },
    //                 didDrawPage: (data) => {
    //                     // Afficher le titre uniquement sur la première page
    //                     if (isFirstPage) {
    //                         doc.text(title, 14, 15);
    //                         isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
    //                     }
    
    //                     // Gérer la position de départ du tableau pour le centrage
    //                     const tableWidth = data.table.width;
    //                     const pageWidth = doc.internal.pageSize.width;
    //                     const x = (pageWidth - tableWidth) / 2;
    //                     const y = tableStartY - 10;
    //                     data.cursor.y = y;
    //                     data.cursor.x = x;
    //                 },
    //             };
    
    //             // Entêtes du tableau
    //             const header = colsExpo.map((col) => col.header);
    
    //             // Données du tableau
    //             const data = donnee.map((row, rowIndex) =>
    //                 colsExpo.map((col) => {
    //                     if (col.field == "num") {
    //                         return rowIndex + 1; // Auto-incrémenter le numéro de ligne
    //                     } else if (col.field == "nom_direction") {
    //                         return row.direction && row.direction.length > 0 
    //                             ? row.direction.map(d => d.nom_direction).join(', ') 
    //                             : "";
    //                     } else {
    //                         return row[col.field]; // Accéder aux données avec le champ de la colonne
    //                     }
    //                 })
    //             );
    
    //             // Génération du tableau dans le PDF
    //             doc.autoTable(header, data, tableConfig);
    
    //             // Enregistrement du PDF
    //             doc.save("ressources PNN.pdf");
    //         });
    //     });
    // };


    const exportPdf = (donnee) => {
        import("jspdf").then((jsPDF) => {
          import("jspdf-autotable").then(() => {
            // const doc = new jsPDF.default();
            const doc = new jsPDF.default({ orientation: "landscape" }); // Spécifie le mode paysage
    
            const title = "Liste des obligations règlementaire"; // Titre de votre document
            const tableStartY = 20; // Position de départ du tableau
            let isFirstPage = true; // Indicateur pour la première page
    
            // Configuration du tableau
            const tableConfig = {
              startY: tableStartY, // Position de départ du tableau
              theme: "grid", // Thème du tableau
              styles: {
                overflow: "linebreak", // Gérer le renvoi à la ligne automatique
              },
              columnStyles: {
                // Styles des colonnes
                // Ajoutez les styles pour chaque colonne si nécessaire
              },
              didDrawPage: (data) => {
                // Afficher le titre uniquement sur la première page
                if (isFirstPage) {
                  doc.text(title, 14, 15);
                  isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
                }
    
                // Gérer la position de départ du tableau pour le centrage
                const tableWidth = data.table.width;
                const pageWidth = doc.internal.pageSize.width;
                const x = (pageWidth - tableWidth) / 2;
                const y = tableStartY - 10;
                data.cursor.y = y;
                data.cursor.x = x;
              },
            };
    
            // Entêtes du tableau
            const header = exportColumns.map((col) => col.title);
    
            // Données du tableau
            const data = donnee.map((row, rowIndex) =>
              exportColumns.map((col) => {
                if (col.dataKey == "num") {
                  return rowIndex + 1; // Auto-incrémenter le numéro de ligne
                } else {
                  return row[col.dataKey]; // Accéder aux données avec le champ de la colonne
                }
              })
            );
    
            // Génération du tableau dans le PDF
            doc.autoTable(header, data, tableConfig);
    
            // Enregistrement du PDF
            doc.save("Obligations_règlementaire.pdf");
          });
        });
      };


    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            // Créer les entêtes à partir de colsExport
            const headers = colsExpo.map(col => col.header);
            const fields = colsExpo.map(col => col.field);
    
            // Créer les données formatées
            const data = props.expoData.map((item, index) => {
                let rowData = {};
                fields.forEach((field, i) => {
                    if (field === 'num') {
                        rowData[headers[i]] = index + 1; // Numéro auto-incrémenté
                    } else {
                        rowData[headers[i]] = item[field];
                    }
                });
                return rowData;
            });
    
            // Ajouter les entêtes comme première ligne des données
            const worksheetData = [headers, ...data.map(Object.values)];
    
            // Créer la feuille de calcul à partir des données formatées
            const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    
            // Générer le buffer Excel
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    
            // Sauvegarder le fichier Excel
            saveAsExcelFile(excelBuffer, 'Obligations_règlementaire');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="container_header_data_table">
            <div className="flex align-items-center">
                
                <div className="p-input-icon-left ml-3">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                </div>
            </div>
            <div className='container_export'>
            <OverlayTrigger
          placement={"top"}
          overlay={<Tol>Exportation PDF.</Tol>}
        >
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={()=>exportPdf(props.expoData)} className="" data-pr-tooltip="PDF" style={{borderRadius : 30}} severity="danger" size="small"/>
                </OverlayTrigger>
                <OverlayTrigger
          placement={"top"}
          overlay={<Tol>Exportation Excel.</Tol>}
        >
                <Button type="button" icon="pi pi-file-excel" className=""severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{borderRadius : 30}} size="small"/>
                </OverlayTrigger>
                <OverlayTrigger
          placement={"top"}
          overlay={<Tol>Exportation Csv.</Tol>}
        >
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{borderRadius : 30}} size="small" severity="secondary"/>
                </OverlayTrigger>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                <a onClick={() => handleEdit(rowData)} class="badge bg-success p-2 mr-1" style={{borderRadius : 30}}><i class="ri-pencil-line  ri-2x"></i><span></span></a> 

{/* <a onClick={() => handleDelete(rowData)} class="badge bg-danger p-2" style={{borderRadius : 30}}><i class="ri-delete-bin-6-line  ri-2x"></i><span></span></a>  */}
               
            </div>
        );
    };

    const handleEdit = (rowData) => {
       
        navigate(`/update-obligation-reglmt/${rowData.id_obligation_reg}`)
    };

    const handleDelete = (rowData) => {
        // props.handleShow1()
    };

    const rowClassName = (rowData, index) => {
        return index !==  props.obg.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
    };

    return (
        <div className="card" style={{width: '100%'}}>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

            {
                props.obg.length > 0 ?
            <DataTable
                ref={dt}
                value={props.obg}
                header={header}
                tableStyle={{ minWidth: '100%', fontSize : 12 }}
                globalFilter={globalFilter}
                emptyMessage="Pas de résultat."
                paginator
                rows={10}
                rowClassName={rowClassName} 

                selectionMode={'checkbox'}
            >
                {cols.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} sortable={col.sortable}
                        body={col.field === 'actions' ? actionBodyTemplate : col.field === "num"
                            ? (data, options) => options.rowIndex + 1 : null} />
                ))}
            </DataTable>
            
            :
            <Spinner
        as="span"
        animation="border"
        size="md"
        role="status"
        aria-hidden="true"
      />
            }

        </div>
    );
}
