import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";
import moment from "moment";
import { getUserInfo } from "../../../redux/slice/userSlice.js";

import {
  useGetObligationParTypeOpQuery,
  useGetObligationParCatQuery,
  useGetObligationRespecterParOpQuery,
  useGetObligationNonRespecterParOpQuery,
  useGetCatObligationQuery,
  useGetOperateurQuery,
  useGetTypeObligationQuery,
  useGetTypeOperateurQuery,
  useGetAllDirectionQuery,
  useGetLesObligationsAffecteeQuery,
  useGetObligationsParTypOpQuery,
} from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";

import TableClassification from "./dataTables/TableClassification.js";
import { useGetObligationsQuery } from "../../../redux/slice/obligationApiSlice.js";
import { useCreateLogPageMutation } from "../../../redux/apiSlice/logPageApiSlice.js";

export default function ListePlainte() {
  const { data: typeOp } = useGetTypeOperateurQuery();

  const { data: obligationAffectee } = useGetLesObligationsAffecteeQuery();

  const initOb = {
    id_cat_obligation_reg: "",
    id_operateur: "",
    id_type_ob: "",
    id_type_op: "",
    id_direction: "",
  };
  const [lesOb, setLesOb] = useState({ ...initOb });

  const [dataOb, setDataOb] = useState([]);

  const { data: obParTypOp } = useGetObligationsParTypOpQuery({
    id_type_op: lesOb.id_type_op,
  });

  const nbreObligationRespectee = (tableau, statut) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter((ob) => ob.statut === statut).length;
    }
    return 0;
  };

  const comparerDate = (dateVigeur, periodicite) => {
    const dateFuture = moment(dateVigeur)
      .add(parseInt(periodicite), "days")
      .format("YYYY-MM-DD");
    const dateAujourdhui = moment().format("YYYY-MM-DD");
    return moment(dateFuture).isBefore(dateAujourdhui);
  };

  const obligationEnCours = (tableau) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter(
        (ob) =>
          comparerDate(
            ob?.date_vigueur,
            ob?.Obligation_reg?.Periodicite?.lib_periode
          ) === true
      ).length;
    }
    return 0;
  };

  const recupererInfo = (champ, value) => {
    setLesOb({ ...lesOb, [champ]: value });
  };
  let nbreObLieeTypOp = obParTypOp?.reduce(
    (total, op) => total + op?.Obligation_operateurs.length,
    0
  );

  const classementOp = () => {
    const infosSurObligationOp = obParTypOp?.map((valeur) => {
      let cumulePoid = valeur?.Obligation_operateurs?.reduce(
        (total, poid) => total + poid?.Obligation_reg?.poids,
        0
      );
      const resultatsDenominateurTauxConformite =
        nbreObLieeTypOp - obligationEnCours(valeur?.Obligation_operateurs);
      const tauxConformitee =
        resultatsDenominateurTauxConformite > 0
          ? (
              (nbreObligationRespectee(valeur?.Obligation_operateurs, 0) /
                resultatsDenominateurTauxConformite) *
              100
            ).toFixed(2)
          : 0;
      return {
        nom_operateur: valeur?.nom_operateur,
        nbre_totale_ob: nbreObLieeTypOp,
        nbre_ob: valeur?.Obligation_operateurs?.length,
        ob_resp: nbreObligationRespectee(valeur?.Obligation_operateurs, 1),
        ob_non_resp: nbreObligationRespectee(valeur?.Obligation_operateurs, 0),
        ob_en_cours: obligationEnCours(valeur?.Obligation_operateurs),
        nbre_total_poids: cumulePoid,
        taux_conformitee: tauxConformitee,
      };
    });
    const nonZeroObOperators = infosSurObligationOp?.filter(
      (op) => op.nbre_ob !== 0
    );

    nonZeroObOperators?.sort((a, b) => {
      // if (a.ob_resp === b.ob_resp) {
      //   if (a.nbre_ob === b.nbre_ob) {
      //     return a.nom_operateur.localeCompare(b.nom_operateur);
      //   }

      //   return a.nbre_ob - b.nbre_ob;
      // }
      if (a.nbre_total_poids === b.nbre_total_poids) {
        return b.ob_resp - a.ob_resp;
      }

      return b.nbre_total_poids - a.nbre_total_poids;
    });

    const updatedInfosSurObligationOp = nonZeroObOperators?.map((op, index) => {
      const suffix = index === 0 ? "er" : "em";
      return {
        ...op,
        rang: `${index + 1}${suffix}`,
      };
    });
    if (updatedInfosSurObligationOp?.length > 0)
      setDataOb(() => [...updatedInfosSurObligationOp]);
    else setDataOb([]);
  };

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({ content: "Classement opérateur" }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);

  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
  }, []);
  useEffect(() => {
    classementOp();
  }, [obligationAffectee, obParTypOp]);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Obligation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Classement des acteurs</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row className="g-2">
              {/* <Col sm="12" xl="12">
                <Card className="card-one" style={{ height: "180px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <Row className="g-2">
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Nombre d'obligation
                              </label>

                              <h3 className="">
                                {dataOb?.length == 0 ? 0 : dataOb?.length}
                              </h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length == 0
                                  ? 0 + "%"
                                  : (dataOb?.length * 100) / dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                nombre d'obligations respectées
                              </label>

                              <h3 className="">
                                {nbreObligationRespectee(dataOb, 1)}
                              </h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length === 0
                                  ? 0 + "%"
                                  : (nbreObligationRespectee(dataOb, 1) * 100) /
                                      dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                nombres d'obligations en cours
                              </label>

                              <h3 className="">{obligationEnCours(dataOb)}</h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length === 0
                                  ? 0 + "%"
                                  : (obligationEnCours(dataOb) * 100) /
                                      dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Nombre d'obligations non respectées
                              </label>

                              <h3 className="">
                                {nbreObligationRespectee(dataOb, 0)}
                              </h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length === 0
                                  ? 0 + "%"
                                  : (nbreObligationRespectee(dataOb, 0) * 100) /
                                      dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col xl="12" className="d-flex flex-column gap-2">
                <Row className="g-2">
                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="typeOb">Type d'operateur:</Form.Label>

                    <Form.Select
                      id="typeOp"
                      value={lesOb.id_type_op}
                      onChange={(e) =>
                        recupererInfo("id_type_op", parseInt(e.target.value))
                      }
                    >
                      <option value="">Choisir</option>
                      {typeOp?.map((typOp, key) => (
                        <option key={key} value={typOp.id_type_op}>
                          {typOp.lib_type_op}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  {/* <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">
                      Date de début *:
                    </Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de début"
                      value={timestamps.date_debut}
                      onChange={(e) =>
                        obligationsPeriodeDonnee("date_debut", e.target.value)
                      }
                    />
                    {errorMessage.msgDebut && (
                      <span className="text-danger">
                        {errorMessage.msgDebut}
                      </span>
                    )}
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">Date de fin *:</Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de fin"
                      value={timestamps.date_fin}
                      onChange={(e) =>
                        obligationsPeriodeDonnee("date_fin", e.target.value)
                      }
                    />
                    {errorMessage.msgFin && (
                      <span className="text-danger">{errorMessage.msgFin}</span>
                    )}
                  </Col> */}
                </Row>
              </Col>

              <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des obligations
                </p>
                <TableClassification ob={dataOb} />
                {/* <TableClassification ob={operateurLieeTypeOperateur} /> */}
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
