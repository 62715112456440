import { apiSlice } from "../api/apiSlice"

export const directionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDirections: builder.query({
            query: () => ({
                url: '/obligation/direction',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'Direction', id: 'LIST' },
                    ...result.map(item => ({ type: 'Direction', id: item.id_direction }))
                ] : ['Direction']
            }
        }),

        createDirection: builder.mutation({
            query: body => ({
                url: '/obligation/direction/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Direction']
        }),

        updateDirection: builder.mutation({
            query: body => ({
                url: `/obligation/direction/update/${body.id_direction}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Direction']
        }),

     deleteDirection: builder.mutation({
           
            query: (id) => ({
              url: `/obligation/direction/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Direction'],
          }),
    })
})

export const {
    useGetDirectionsQuery,
    useCreateDirectionMutation,
    useUpdateDirectionMutation,
    useDeleteDirectionMutation,
} = directionApiSlice