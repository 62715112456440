import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";

//Operateur et clients
import { useGetTypeOperateurQuery } from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";
import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";
import {
  useGetAttrIspcQuery,
  useGetAttrMncQuery,
  useGetAttrUssdQuery,
  useGetClientsQuery,
  useGetServiceTrancheQuery,
} from "../../../redux/apiSlice/numerotationApiSlice.js";

import {
  useGetListeIspcQuery,
  useGetListeMncQuery,
  useGetUssdQuery,
} from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";
import { getUserInfo } from "../../../redux/slice/userSlice.js";

import TableNumerotationCat from "./dataTables/TableNumerotationCat.js";
import { useCreateLogPageMutation } from "../../../redux/apiSlice/logPageApiSlice.js";

export default function ListePlainte() {
  const { data: lesOps } = useGetOperateursQuery();
  const { data: typeOp } = useGetTypeOperateurQuery();
  const { data: lesClients } = useGetClientsQuery();
  const { data: attrMnc } = useGetAttrMncQuery();
  const { data: attrIspc } = useGetAttrIspcQuery();
  const { data: attrUssd } = useGetAttrUssdQuery();
  const { data: ussd = [] } = useGetUssdQuery();
  const { data: liste_ussd = [] } = useGetUssdQuery();
  const { data: liste_ispc = [] } = useGetListeIspcQuery();
  const { data: liste_mnc = [] } = useGetListeMncQuery();
  const { data: services = [] } = useGetServiceTrancheQuery();
  const initialisateur = {
    id_operateur: "",
    id_clt: "",
    id_status: "",
    id_service: "",
    cat_ressource: "",
    id_type_op: "",
    id_tranche_ussd: "",
    elt_filtre: "",
  };
  const [lesChamp, setLesChamp] = useState({ ...initialisateur });
  const [dataAttr, setDataAttr] = useState([]);
  const [dataUssdService, setDataUssdService] = useState([]);
  const [autre, setAutre] = useState([]);

  const [tableTrancheUssd, setTableTrancheUssd] = useState([]);
  const [tableServices, setTableServices] = useState([]);
  const [tableStatuts, setTableStatuts] = useState([]);
  const [tableOp, setTableOp] = useState([]);
  const [selectedOptionTrancheUssd, setSelectedOptionTrancheUssd] = useState(
    []
  );
  const [selectedOptionServices, setSelectedOptionServices] = useState([]);
  const [selectedOptionStatuts, setSelectedOptionStatuts] = useState([]);
  const [selectedOptionOp, setSelectedOptionOp] = useState([]);
console.log('les service ssd',services);
  const tranchesUssd = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
    {
      value: 6,
      label: 6,
    },
    {
      value: 7,
      label: 7,
    },
    {
      value: 8,
      label: 8,
    },
    {
      value: 9,
      label: 9,
    },
  ];

  const lesStatuts = [
    {
      value: 1,
      label: "Libre",
    },
    {
      value: 2,
      label: "En instance",
    },
    {
      value: 3,
      label: "Réservée",
    },
    {
      value: 4,
      label: "Attribuée",
    },
    {
      value: 5,
      label: "Bloquée",
    },
    {
      value: 6,
      label: "Inutilisable",
    },
  ];

  useEffect(() => {
    setTableTrancheUssd(() =>
      tranchesUssd?.map((ussd) => ({
        value: ussd.value,
        label: ussd.label,
      }))
    );
  }, [lesChamp.id_tranche_ussd]);

  useEffect(() => {
    const selectedIdsTrancheUssd = selectedOptionTrancheUssd.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_tranche_ussd: selectedIdsTrancheUssd });
    if (selectedIdsTrancheUssd.length > 0) {
      ressourcesAttribueeOpClt("id_tranche_ussd", selectedIdsTrancheUssd);
    }
  }, [selectedOptionTrancheUssd]);

  useEffect(() => {
    setTableServices(() =>
      services
        ?.filter(
          (type) =>
            type.id_service === 15 ||
            type.id_service === 16 ||
            type.id_service === 17 ||
            type.id_service === 18
        )
        ?.map((serv) => ({
          value: serv.id_service,
          label: serv.lib_service,
        }))
    );
  }, [lesChamp.id_service]);

  useEffect(() => {
    const selectedIdsServices = selectedOptionServices.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_service: selectedIdsServices });
    if (selectedIdsServices.length > 0) {
      ressourcesAttribueeOpClt("id_service", selectedIdsServices);
    }
  }, [selectedOptionServices]);

  useEffect(() => {
    setTableStatuts(() =>
      lesStatuts?.map((statut) => ({
        value: statut.value,
        label: statut.label,
      }))
    );
  }, [lesChamp.id_status]);

  useEffect(() => {
    const selectedIdsStatuts = selectedOptionStatuts.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_status: selectedIdsStatuts });
    if (selectedIdsStatuts.length > 0) {
      ressourcesAttribueeOpClt("id_status", selectedIdsStatuts);
    }
  }, [selectedOptionStatuts]);

  useEffect(() => {
    setTableOp(() =>
      lesOps
        ?.filter((op) => op.id_type_op === parseInt(lesChamp.id_type_op))
        .map((op) => ({
          value: op.id_operateur,
          label: op.nom_operateur,
        }))
    );
  }, [lesChamp.id_type_op, lesOps]);

  useEffect(() => {
    const selectedIdsOp = selectedOptionOp.map((option) => option.value);
    setLesChamp({ ...lesChamp, id_operateur: selectedIdsOp });
    if (selectedIdsOp.length > 0) {
      ressourcesAttribueeOpClt("id_operateur", selectedIdsOp);
    }
  }, [selectedOptionOp]);

  const stat = (value) => {
    switch (value) {
      case 1:
        return "Libre";
      case 2:
        return "En instance";
      case 3:
        return "Réservée";
      case 4:
        return "Attribuée";
      case 5:
        return "Bloquée";
      case 6:
        return "Inutilisable";
      default:
        break;
    }
  };

  const cat_ressource_selectionnee = (val) => {
    const categories = ["mnc", "ispc", "ussd"];
    if (categories.includes(val)) {
      return val;
    } else {
      return categories[val];
    }
  };

  const liste_cat_ress = (typ) => {
    switch (typ) {
      case "mnc":
        setDataAttr(() => {
          const tabFormater = liste_mnc?.map((us) => {
            return {
              lib: us?.lib_mnc,
              statut: stat(us?.id_stat_num),
            };
          });

          return tabFormater;
        });
        break;
      case "ispc":
        setDataAttr(() => {
          const tabFormater = liste_ispc?.map((us) => {
            return {
              lib: us?.lib_ispc,
              statut: stat(us?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ussd":
        setDataAttr(() => {
          const tabFormater = liste_ussd?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });
          if (tabFormater && tabFormater.length > 0)
            return tabFormater
              .slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          return [];
        });
        break;
      default:
        setDataAttr([]);
        break;
    }
  };
  //
  const liste_ress_par_statut = (statut, cat) => {
    switch (cat) {
      case "mnc":
        setDataAttr(() => {
          const ress_mnc = liste_mnc.filter((ress) =>
            statut.includes(ress.id_stat_num)
          );
          const tabFormater = ress_mnc?.map((us) => {
            return {
              lib: us?.lib_mnc,
              statut: stat(us?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ispc":
        setDataAttr(() => {
          const ress_ispc = liste_ispc.filter((ress) =>
            statut.includes(ress.id_stat_num)
          );
          const tabFormater = ress_ispc?.map((us) => {
            return {
              lib: us?.lib_ispc,
              statut: stat(us?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ussd":
        // if (parseInt(statut) === 4) {
        //   setDataAttr(() => {
        //     var ress_ussd = [];
        //     if (lesChamp.id_service !== "") {
        //       ress_ussd = dataUssdService?.filter(
        //         (ress) => ress.id_stat_num === parseInt(statut)
        //       );
        //     } else {
        //       ress_ussd = ussd?.filter(
        //         (ress) => ress.id_stat_num === parseInt(statut)
        //       );
        //     }

        //     const lesUssdAttribuer = attrUssd?.map((us) => {
        //       const foundUssd = ress_ussd.find(
        //         (dataUs) => dataUs.id_ussd === us.id_ussd
        //       );
        //       if (foundUssd && foundUssd.Ussd_list) {
        //         return {
        //           lib: foundUssd.Ussd_list.libelle,
        //           statut: stat(foundUssd.id_stat_num),
        //         };
        //       }
        //       return {
        //         lib: "neant",
        //         statut: "neant",
        //       };
        //     });

        //     if (lesUssdAttribuer && lesUssdAttribuer.length > 0)
        //       return lesUssdAttribuer
        //         .slice()
        //         .sort((a, b) => a.lib.localeCompare(b.lib));
        //     return [];
        //   });
        // } else {
        //   setDataAttr(() => {
        //     var ress_ussd = [];
        //     if (lesChamp.id_service !== "") {
        //       ress_ussd = dataUssdService?.filter(
        //         (ress) => ress.id_stat_num === parseInt(statut)
        //       );
        //     } else {
        //       ress_ussd = ussd?.filter(
        //         (ress) => ress.id_stat_num === parseInt(statut)
        //       );
        //     }

        //     const tabFormater = ress_ussd?.map((us) => {
        //       return {
        //         lib: us?.Ussd_list?.libelle,
        //         statut: stat(us?.id_stat_num),
        //       };
        //     });
        //     if (tabFormater && tabFormater.length > 0)
        //       return tabFormater
        //         .slice()
        //         .sort((a, b) => a.lib.localeCompare(b.lib));
        //     return [];
        //   });
        // }
        setDataAttr(() => {
          var ress_ussd = [];
          if (lesChamp.id_service !== "" || lesChamp.id_tranche_ussd !== "") {
            ress_ussd = dataUssdService?.filter((ress) =>
              statut.includes(ress.id_stat_num)
            );
          } else {
            ress_ussd = ussd?.filter((ress) =>
              statut.includes(ress.id_stat_num)
            );
          }

          const tabFormater = ress_ussd?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });
          if (tabFormater && tabFormater.length > 0)
            return tabFormater
              .slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          return [];
        });
        break;
      default:
        setDataAttr([]);
        break;
    }
  };

  const recuperation_op_client = (elt_filtre, cat) => {
    switch (cat) {
      case "mnc":
        setDataAttr(() => {
          let mnc_filtrer = attrMnc.filter(
            (ress_mnc, key) =>
              ress_mnc?.Operateur?.id_type_op === parseInt(elt_filtre)
          );

          const tabFormater = mnc_filtrer?.map((us) => {
            return {
              lib: us?.Mnc?.lib_mnc,
              statut: stat(us?.Mnc?.id_stat_num),
            };
          });
          return tabFormater;
        });

        break;
      case "ispc":
        let ispc_filtrer = attrIspc.filter(
          (ress_ispc, key) =>
            ress_ispc?.Operateur?.id_type_op === parseInt(elt_filtre)
        );

        setDataAttr(() => {
          const tabFormater = ispc_filtrer?.map((us) => {
            return {
              lib: us?.Ispc?.lib_ispc,
              statut: stat(us?.Ispc?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ussd":
        let ussd_filtrer = attrUssd.filter(
          (ress_ussd) =>
            ress_ussd?.Operateur?.id_type_op === parseInt(elt_filtre) &&
            ress_ussd?.Ussd.id_stat_num === 4
        );

        let lesUssdOpOuClt = [];
        if (lesChamp.id_service !== "" || lesChamp.id_tranche_ussd !== "") {
          lesUssdOpOuClt = ussd_filtrer.map((us) => {
            return dataUssdService.find(
              (dataUs) => dataUs.id_ussd == us.id_ussd //&& dataUs?.id_stat_num === 4
            );
          });
        } else {
          lesUssdOpOuClt = ussd_filtrer.map((us) => {
            return ussd.find(
              (dataUs) => dataUs.id_ussd == us.id_ussd //&& dataUs?.id_stat_num === 4
            );
          });
        }

        setDataAttr(() => {
          const tabFormater = lesUssdOpOuClt?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });

          if (tabFormater && tabFormater.length > 0) {
            // Filtrer les éléments qui ont `lib` défini avant de trier
            const tabFormaterWithLib = tabFormater.filter((item) => item.lib);

            return tabFormaterWithLib
              ?.slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          }

          return [];
        });

        break;
      default:
        setDataAttr([]);
        break;
    }
  };

  const ress_op_ou_client = (val, cat) => {
    switch (cat) {
      case "ussd":
        let ussd_filtrer = attrUssd.filter(
          (ress_ussd, key) =>
            ress_ussd?.Operateur?.id_type_op ===
              parseInt(lesChamp.id_type_op) &&
            val.includes(ress_ussd?.Operateur?.id_operateur)
        );

        // let opOuClt = val == "0" ? lesChamp.id_operateur : lesChamp.id_clt;
        let ussdOpOuClt = ussd_filtrer.filter((ussd, key) =>
          val.includes(ussd?.Operateur?.id_operateur)
        );

        let lesUssdOpOuClt = [];
        if (lesChamp.id_service !== "" || lesChamp.id_tranche_ussd !== "") {
          lesUssdOpOuClt = ussd_filtrer.map((us) => {
            return dataUssdService.find(
              (dataUs) =>
                dataUs.id_ussd === us.id_ussd && dataUs?.id_stat_num === 4
            );
          });
        } else {
          lesUssdOpOuClt = ussd_filtrer.map((us) => {
            return ussd.find(
              (dataUs) => dataUs.id_ussd === us.id_ussd //&& dataUs?.id_stat_num === 4
            );
          });
        }

        setDataAttr(() => {
          const tabFormater = lesUssdOpOuClt?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });
          if (tabFormater && tabFormater.length > 0) {
            const tabFormaterWithLib = tabFormater.filter((item) => item.lib);
            return tabFormaterWithLib
              .slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          }
          return [];
        });
        break;
      case "ispc":
        let ispc_filtrer = attrIspc.filter(
          (ress_ispc, key) =>
            ress_ispc?.Operateur?.id_type_op === parseInt(lesChamp.id_type_op)
        );
        // let opOuCltIspc =
        //   lesChamp.elt_filtre == "0" ? lesChamp.id_operateur : lesChamp.id_clt;
        let ispcOpOuClt = ispc_filtrer.filter((ispc, key) =>
          val.includes(ispc?.id_occupant)
        );
        setDataAttr(() => {
          const tabFormater = ispcOpOuClt?.map((us) => {
            return {
              lib: us?.Ispc?.lib_ispc,
              statut: stat(us?.Ispc?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "mnc":
        let mnc_filtrer = attrMnc.filter(
          (ress_mnc, key) =>
            ress_mnc?.Operateur?.id_type_op === parseInt(lesChamp.id_type_op)
        );
        // let opOuCltMnc =
        //   lesChamp.elt_filtre == "0" ? lesChamp.id_operateur : lesChamp.id_clt;
        let mncOpOuClt = mnc_filtrer.filter((mnc, key) =>
          val.includes(mnc?.id_occupant)
        );
        setDataAttr(() => {
          const tabFormater = mncOpOuClt?.map((us) => {
            return {
              lib: us?.Mnc?.lib_mnc,
              statut: stat(us?.Mnc?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      default:
        break;
    }
  };

  const ressourcesAttribueeOpClt = (champ, value) => {
    if (value === "") {
      setLesChamp(initialisateur);
      setDataAttr([]);
      return;
    }
    const newValue = Array.isArray(value) ? value.map(Number) : value;
    setLesChamp({ ...lesChamp, [champ]: newValue });

    switch (champ) {
      case "cat_ressource":
        liste_cat_ress(newValue);
        break;
      case "id_status":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          liste_ress_par_statut(newValue, categorie_r);
        } else {
          setDataAttr([]);
        }

        break;
      case "id_type_op":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          recuperation_op_client(newValue, categorie_r);
        } else {
          setDataAttr([]);
        }
        break;
      case "id_operateur":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          ress_op_ou_client(newValue, categorie_r);
        } else {
          setDataAttr([]);
        }
        break;
      case "id_clt":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          ress_op_ou_client(newValue, categorie_r);
        } else {
          setDataAttr([]);
        }
        break;
      case "id_service":
        if (lesChamp.cat_ressource === "ussd") {
          setDataAttr(() => {
            const trieParService = liste_ussd?.filter((us) =>
              newValue.includes(us.id_service)
            );
            setDataUssdService(trieParService);
            const tabFormater = trieParService?.map((us) => {
              return {
                lib: us?.Ussd_list?.libelle,
                statut: stat(us?.id_stat_num),
              };
            });
            if (tabFormater && tabFormater.length > 0)
              return tabFormater
                .slice()
                .sort((a, b) => a.lib.localeCompare(b.lib));
            return [];
          });
        } else {
          setDataAttr([]);
        }
        break;
      case "id_tranche_ussd":
        if (lesChamp.cat_ressource === "ussd") {
          setDataAttr(() => {
            const trieParTrancheUssd = liste_ussd?.filter((us) =>
              newValue.includes(us.Ussd_list.type_ussd)
            );
            setDataUssdService(trieParTrancheUssd);
            const tabFormater = trieParTrancheUssd?.map((us) => {
              return {
                lib: us?.Ussd_list?.libelle,
                statut: stat(us?.id_stat_num),
              };
            });
            if (tabFormater && tabFormater.length > 0)
              return tabFormater
                .slice()
                .sort((a, b) => a.lib.localeCompare(b.lib));
            return [];
          });
        } else {
          setDataAttr([]);
        }
        break;
      default:
        break;
    }
  };
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({ content: "Statistique ussd/mnc/ispc" }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);
  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
  }, []);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Numerotation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistique attribution</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row className="g-2">
              <Col sm="12" xl="4">
                <Card className="card-one" style={{ height: "120px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <Row>
                        <Col
                          sm="12"
                          xl="12"
                          className="d-flex flex-column justify-content-center align-items-center"
                        >
                          <label className="fs-sm fw-medium mb-2 mb-xl-1">
                            Nombre de ressources
                          </label>

                          <h4 className="finance-value">{dataAttr?.length}</h4>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" xl="12">
                <Row>
                  <Col sm="12" xl="2">
                    <Form.Label htmlFor="categorie_ressources">
                      Catégorie ressources :
                    </Form.Label>

                    <Form.Select
                      id="categorie_ressources"
                      value={lesChamp.cat_ressource}
                      onChange={(e) =>
                        ressourcesAttribueeOpClt(
                          "cat_ressource",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="ussd">Ussd</option>
                      <option value="ispc">Ispc</option>
                      <option value="mnc">Mnc</option>
                    </Form.Select>
                  </Col>
                  {lesChamp.cat_ressource === "ussd" && (
                    <>
                      <Col sm="12" xl="4">
                        <Form.Label>Tranche(USSD)</Form.Label>
                        <Select
                          options={tableTrancheUssd}
                          isSearchable={true}
                          isMulti
                          onChange={setSelectedOptionTrancheUssd}
                        />
                        {/* <Form.Select
                          onChange={(e) =>
                            ressourcesAttribueeOpClt(
                              "id_tranche_ussd",
                              parseInt(e.target.value)
                            )
                          }
                        >
                          <option value="">Choisir</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </Form.Select> */}
                      </Col>
                      <Col sm="12" xl="4">
                        <Form.Label>Service(Groupe)</Form.Label>
                        <Select
                          options={tableServices}
                          isSearchable={true}
                          isMulti
                          onChange={setSelectedOptionServices}
                        />
                        {/* <Form.Select
                          onChange={(e) =>
                            ressourcesAttribueeOpClt(
                              "id_service",
                              e.target.value
                            )
                          }
                        >
                          <option>Choisir</option>
                          {services
                            .filter(
                              (type) =>
                                type.id_service === 15 ||
                                type.id_service === 16 ||
                                type.id_service === 17 ||
                                type.id_service === 18
                            )
                            .map((type, i) => (
                              <option key={i} value={type.id_service}>
                                {type.lib_service}
                              </option>
                            ))}
                        </Form.Select> */}
                      </Col>
                    </>
                  )}

                  <Col sm="12" xl="2">
                    <Form.Label htmlFor="statut">Statuts :</Form.Label>
                    <Select
                      options={tableStatuts}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionStatuts}
                    />
                    {/* <Form.Select
                      id="statut"
                      value={lesChamp.id_status}
                      onChange={(e) =>
                        ressourcesAttribueeOpClt("id_status", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="3">Réservée</option>
                      <option value="1">Libre</option>
                      <option value="2">En instance</option>
                      <option value="4">Attribuée</option>
                      <option value="6">Inutilisable</option>
                      <option value="5">Bloquée</option>
                    </Form.Select> */}
                  </Col>
                  <Col sm="12" xl="6">
                    <Form.Label htmlFor="elt_filtre">
                      Type d'opérateur :
                    </Form.Label>

                    <Form.Select
                      id="typ_op"
                      value={lesChamp.id_type_op}
                      onChange={(e) =>
                        ressourcesAttribueeOpClt(
                          "id_type_op",
                          parseInt(e.target.value)
                        )
                      }
                    >
                      <option value="">Choisir</option>
                      {typeOp?.map((typOp, key) => (
                        <option key={key} value={typOp.id_type_op}>
                          {typOp.lib_type_op}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="6">
                    <Form.Label htmlFor="op">Opérateur :</Form.Label>
                    <Select
                      options={tableOp}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionOp}
                    />
                    {/* <Form.Select
                      id="op"
                      value={lesChamp.id_operateur}
                      onChange={(e) =>
                        ressourcesAttribueeOpClt(
                          "id_operateur",
                          parseInt(e.target.value)
                        )
                      }
                    >
                      <option value="">Choisir</option>

                      {lesOps?.map((op, key) => {
                        if (op.id_type_op === parseInt(lesChamp.id_type_op)) {
                          return (
                            <option key={key} value={op.id_operateur}>
                              {op.nom_operateur}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Form.Select> */}
                  </Col>
                </Row>
              </Col>
              <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des ressources
                </p>
                <TableNumerotationCat cat_ressource={dataAttr} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
