import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";
import moment from "moment";
import { getUserInfo } from "../../../redux/slice/userSlice.js";

import {
  useGetObligationParTypeOpQuery,
  useGetObligationParCatQuery,
  useGetObligationRespecterParOpQuery,
  useGetObligationNonRespecterParOpQuery,
  useGetCatObligationQuery,
  useGetOperateurQuery,
  useGetTypeObligationQuery,
  useGetTypeOperateurQuery,
  useGetAllDirectionQuery,
  useGetLesObligationsAffecteeQuery,
} from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";

import TableObligation from "./dataTables/TableObligation.js";
import { useGetObligationsQuery } from "../../../redux/slice/obligationApiSlice.js";
import { useCreateLogPageMutation } from "../../../redux/apiSlice/logPageApiSlice.js";

export default function ListePlainte() {
  const { data: allObligation } = useGetObligationsQuery();
  const { data: categorieOb } = useGetCatObligationQuery();
  const { data: operateur } = useGetOperateurQuery();
  const { data: typeOb } = useGetTypeObligationQuery();
  const { data: typeOp } = useGetTypeOperateurQuery();
  const { data: direction } = useGetAllDirectionQuery();
  const { data: obligationAffectee } = useGetLesObligationsAffecteeQuery();

  const initOb = {
    id_cat_obligation_reg: "",
    id_operateur: "",
    id_type_ob: "",
    id_type_op: "",
    id_direction: "",
  };
  const [lesOb, setLesOb] = useState({ ...initOb });
  const [tableOp, setTableOp] = useState([]);
  const [tableCatOb, setTableCatOb] = useState([]);
  const [tableDirection, setTableDirection] = useState([]);
  const [selectedOptionOp, setSelectedOptionOp] = useState([]);
  const [selectedOptionCatOb, setSelectedOptionCatOb] = useState([]);
  const [selectedOptionDirection, setSelectedOptionDirection] = useState([]);

  const [dataOb, setDataOb] = useState([]);
  const [updateResponse, setUpdateResponse] = useState([]);
  const [timestamps, setTimestamps] = useState({
    date_debut: "",
    date_fin: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    msgDebut: "",
    msgFin: "",
  });

  useEffect(() => {
    setTableOp(() =>
      operateur?.data
        ?.filter((op) => op.id_type_op === parseInt(lesOb.id_type_op))
        .map((op) => ({
          value: op.id_operateur,
          label: op.nom_operateur,
        }))
    );
  }, [lesOb.id_type_op, operateur?.data]);

  useEffect(() => {
    const selectedIdsOp = selectedOptionOp.map((option) => option.value);
    setLesOb({ ...lesOb, id_operateur: selectedIdsOp });
    if (selectedIdsOp.length > 0) {
      recupererInfo("id_operateur", selectedIdsOp);
    }
  }, [selectedOptionOp]);

  useEffect(() => {
    setTableCatOb(() =>
      categorieOb?.map((cat) => ({
        value: cat.id_cat_obligation_reg,
        label: cat.lib_cat_obligation_reg,
      }))
    );
  }, [lesOb.id_cat_obligation_reg, categorieOb]);

  useEffect(() => {
    const selectedIdsCatOb = selectedOptionCatOb.map((option) => option.value);
    setLesOb({ ...lesOb, id_cat_obligation_reg: selectedIdsCatOb });
    if (selectedIdsCatOb.length > 0) {
      recupererInfo("id_cat_obligation_reg", selectedIdsCatOb);
    }
  }, [selectedOptionCatOb]);

  useEffect(() => {
    setTableDirection(() =>
      direction?.map((direct) => ({
        value: direct.id_direction,
        label: direct.nom_direction,
      }))
    );
  }, [lesOb.id_direction, direction]);

  useEffect(() => {
    const selectedIdsDirection = selectedOptionDirection.map(
      (option) => option.value
    );
    setLesOb({ ...lesOb, id_direction: selectedIdsDirection });
    if (selectedIdsDirection.length > 0) {
      recupererInfo("id_direction", selectedIdsDirection);
    }
  }, [selectedOptionDirection]);

  const obligationsPeriodeDonnee = (champ, value) => {
    setTimestamps((prevTimestamps) => {
      const formattedValue = moment(value).format("YYYY-MM-DD");
      return { ...prevTimestamps, [champ]: formattedValue };
    });

    if (champ === "date_fin") {
      if (
        timestamps.date_debut &&
        moment(value).isBefore(timestamps.date_debut)
      ) {
        setErrorMessage({
          ...errorMessage,
          msgFin: "Date de fin est antérieur à la date de début.",
          msgDebut: "",
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          msgFin: "",
          msgDebut: "",
        });
      }
    }

    if (champ === "date_debut") {
      if (timestamps.date_fin && moment(value).isAfter(timestamps.date_fin)) {
        setErrorMessage({
          ...errorMessage,
          msgDebut: "Date de début est postérieur à la date de fin.",
          msgFin: "",
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          msgDebut: "",
          msgFin: "",
        });
      }
    }

    if (moment(timestamps.date_debut).isBefore(moment(timestamps.date_fin))) {
      let obligationsExpireeEntreDeuxDate = obligationAffectee.filter(
        (obligation) => {
          const dateFin = moment(obligation?.date_vigueur)
            .add(
              parseInt(obligation?.Obligation_reg?.Periodicite?.lib_periode),
              "days"
            )
            .format("YYYY-MM-DD");
          return moment(dateFin).isBetween(
            moment(timestamps.date_debut),
            moment(timestamps.date_fin),
            null,
            "[]"
          );
        }
      );
      setDataOb(obligationsExpireeEntreDeuxDate);
    }
  };

  const recupererInfo = (champ, value) => {
    if (value === "") {
      setLesOb(initOb);
      setDataOb(obligationAffectee);
      return;
    }

    const newValue = Array.isArray(value) ? value.map(Number) : parseInt(value);

    setLesOb({ ...lesOb, [champ]: newValue });

    switch (champ) {
      case "id_type_ob":
        let typeObligation = obligationAffectee.filter(
          (valeur) => valeur?.Obligation_reg?.id_type_ob === parseInt(value)
        );
        setDataOb(typeObligation);
        break;
      case "id_cat_obligation_reg":
        let categorieObligation = obligationAffectee.filter((valeur) =>
          newValue.includes(valeur?.Obligation_reg?.id_cat_obligation_reg)
        );

        setDataOb(categorieObligation);
        break;
      case "id_type_op":
        let typeOperateur = obligationAffectee.filter(
          (valeur) => valeur?.Operateur?.id_type_op === parseInt(value)
        );

        setDataOb(typeOperateur);
        break;
      case "id_operateur":
        let operateurObligation = obligationAffectee.filter((valeur) =>
          newValue.includes(valeur?.id_operateur)
        );
        setDataOb(operateurObligation);
        break;
      case "id_direction":
        let obligationsDirection = obligationAffectee.filter((obligation) => {
          const directions = obligation?.Obligation_reg?.Directions;
          return (
            directions &&
            directions?.some((direction) =>
              newValue.includes(direction?.id_direction)
            )
          );
        });

        setDataOb(obligationsDirection);
        break;
    }
  };

  const nbreObligationRespectee = (tableau, statut) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter((ob) => ob.statut === statut).length;
    }
    return 0;
  };

  const comparerDate = (dateVigeur, periodicite) => {
    const dateFuture = moment(dateVigeur)
      .add(parseInt(periodicite), "days")
      .format("YYYY-MM-DD");
    const dateAujourdhui = moment().format("YYYY-MM-DD");
    return moment(dateFuture).isBefore(dateAujourdhui);
  };

  const obligationEnCours = (tableau) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter(
        (ob) =>
          comparerDate(
            ob?.date_vigueur,
            ob?.Obligation_reg?.Periodicite?.lib_periode
          ) === true
      ).length;
    }
    return 0;
  };

  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
  }, []);
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({
          content: "Statistique obligation règlémentaire",
        }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);
  useEffect(() => {
    if (obligationAffectee) {
      setDataOb(obligationAffectee);
    }
  }, [obligationAffectee]);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    // if (dataOb && dataOb.length > 0) {
    //   setUpdateResponse((prevOb) => {
    //     const newOb = dataOb.map((ob) => ({
    //       id_obligation_reg: ob.Obligation_reg.id_obligation_reg,
    //       lib_obligation_reg: ob.Obligation_reg.lib_obligation_reg,
    //     }));

    //     const filteredNewOb = newOb.filter(
    //       (newItem) =>
    //         !prevOb.some(
    //           (prevItem) =>
    //             prevItem.id_obligation_reg === newItem.id_obligation_reg
    //         )
    //     );

    //     return [...prevOb, ...filteredNewOb];
    //   });
    // } else {
    //   setUpdateResponse([]);
    // }

    if (dataOb && dataOb.length > 0) {
      const newOb = dataOb.map((ob, key) => ({
        id_obligation_reg: key + 1, //ob.Obligation_reg.id_obligation_reg,
        lib_obligation_reg: ob.Obligation_reg.lib_obligation_reg,
      }));

      setUpdateResponse(newOb);
    } else {
      setUpdateResponse([]);
    }
  }, [dataOb]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Obligation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">
              Statistiques Obligations réglementaires
            </h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <Card className="card-one" style={{ height: "180px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <Row className="g-2">
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Nombre d'obligation
                              </label>

                              <h3 className="">
                                {dataOb?.length == 0 ? 0 : dataOb?.length}
                              </h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length == 0
                                  ? 0 + "%"
                                  : (dataOb?.length * 100) / dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                nombre d'obligations respectées
                              </label>

                              <h3 className="">
                                {nbreObligationRespectee(dataOb, 1)}
                              </h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length === 0
                                  ? 0 + "%"
                                  : (nbreObligationRespectee(dataOb, 1) * 100) /
                                      dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                nombres d'obligations en cours
                              </label>

                              <h3 className="">{obligationEnCours(dataOb)}</h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length === 0
                                  ? 0 + "%"
                                  : (obligationEnCours(dataOb) * 100) /
                                      dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="12" xl="3">
                          <Row className="g-2">
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Nombre d'obligations non respectées
                              </label>

                              <h3 className="">
                                {nbreObligationRespectee(dataOb, 0)}
                              </h3>
                            </Col>
                            <Col sm="12" xl="12">
                              <label className="fs-sm fw-medium mb-2 mb-xl-1">
                                Taux
                              </label>

                              <h3 className="">
                                {dataOb?.length === 0
                                  ? 0 + "%"
                                  : (nbreObligationRespectee(dataOb, 0) * 100) /
                                      dataOb?.length +
                                    "%"}
                              </h3>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl="12" className="d-flex flex-column gap-2">
                <Row className="g-2">
                  {/* <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="cat">Type d'obligation:</Form.Label>

                    <Form.Select
                      id="cat"
                      value={lesOb.id_type_ob}
                      onChange={(e) =>
                        recupererInfo("id_type_ob", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {typeOb?.map((typ, key) => (
                        <option key={key} value={typ.id_type_ob}>
                          {typ.lib_type_ob}
                        </option>
                      ))}
                    </Form.Select>
                  </Col> */}

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="cat">
                      Categorie d'obligation:
                    </Form.Label>
                    <Select
                      options={tableCatOb}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionCatOb}
                    />
                    {/* <Form.Select
                      id="cat"
                      value={lesOb.id_cat_obligation_reg}
                      onChange={(e) =>
                        recupererInfo("id_cat_obligation_reg", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {categorieOb?.map((cat, key) => (
                        <option key={key} value={cat.id_cat_obligation_reg}>
                          {cat.lib_cat_obligation_reg}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="typeOb">Type d'operateur:</Form.Label>

                    <Form.Select
                      id="typeOp"
                      value={lesOb.id_type_op}
                      onChange={(e) =>
                        recupererInfo("id_type_op", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {typeOp?.map((typOp, key) => (
                        <option key={key} value={typOp.id_type_op}>
                          {typOp.lib_type_op}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="op">Opérateur:</Form.Label>
                    <Select
                      options={tableOp}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionOp}
                    />

                    {/* <Form.Select
                      id="op"
                      value={lesOb.id_operateur}
                      onChange={(e) =>
                        recupererInfo("id_operateur", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {operateur?.data?.map((op, key) => {
                        if (op.id_type_op === lesOb.id_type_op) {
                          return (
                            <option key={key} value={op.id_operateur}>
                              {op.nom_operateur}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Form.Select> */}
                  </Col>
                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="op">Direction en charge:</Form.Label>
                    <Select
                      options={tableDirection}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionDirection}
                    />
                    {/* <Form.Select
                      id="op"
                      value={lesOb.id_direction}
                      onChange={(e) =>
                        recupererInfo("id_direction", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {direction?.map((direct, key) => (
                        <option key={key} value={direct.id_direction}>
                          {direct.nom_direction}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>
                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">
                      Date d'échéance *:
                    </Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de début"
                      value={timestamps.date_debut}
                      onChange={(e) =>
                        obligationsPeriodeDonnee("date_debut", e.target.value)
                      }
                    />
                    {errorMessage.msgDebut && (
                      <span className="text-danger">
                        {errorMessage.msgDebut}
                      </span>
                    )}
                  </Col>

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">
                      Date de fin d'échéance*:
                    </Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de fin"
                      value={timestamps.date_fin}
                      onChange={(e) =>
                        obligationsPeriodeDonnee("date_fin", e.target.value)
                      }
                    />
                    {errorMessage.msgFin && (
                      <span className="text-danger">{errorMessage.msgFin}</span>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des obligations
                </p>
                <TableObligation ob={updateResponse} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
