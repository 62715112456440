import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row,Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableOperateur from "./dataTable/TableOperateur";
import Swal from "sweetalert2";
  import { useGetTypeOperateursQuery } from "../../redux/slice/typeOperateurApiSlice";
  import { useGetOperateursQuery, useCreateOperateursMutation, useUpdateOperateursMutation, useDeleteOperateursMutation } from "../../redux/apiSlice/operateurApiSlice";
  import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
  import { getUserInfo } from '../../redux/slice/userSlice';
  import { useSelector } from "react-redux";

export default function Operateur() {

  // modal 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
 
  const init = {
    nom_operateur: '',
    email_operateur: '',
    num_operateur: '',
    id_type_op : '',
    date_autorization: 0
}

const [form, setForm] = useState({...init})
const [localDate, setLocalDate] = useState("");



  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  const checkEmail = (email) => {

    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email)
  }
  
  
  const [createData, {isLoading: loadCreate}] = useCreateOperateursMutation()
  const [updateData, {isLoading: loadUpdate }] = useUpdateOperateursMutation()
  const [deleteData, {isLoading: loadDelete }] = useDeleteOperateursMutation()
  const {data: typeOperateur = [], refetch: refetchTypeOp, isLoading: loadTypeOp} = useGetTypeOperateursQuery()
  const {data: operateur = [], isLoading: loadOperateur} = useGetOperateursQuery()

const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const changeForm = (field, value) => setForm({...form, [field]: value})

  const save = async() => {
    
    const checkMail = checkEmail(form.email_operateur)


    if (checkMail) {
      try {

        if (form?.id_operateur) {

            await updateData({...form, date_autorization : new Date(localDate).getTime()}).unwrap()
            setForm({...init})
            setLocalDate("")
            Swal.fire({
              title: "SUCCES!",
              text: "Action réussie",
              icon: "success"
            });

        } else {
    
            await createData({...form, date_autorization : new Date(localDate).getTime()}).unwrap()
            setForm({...init})
            setLocalDate("")
            Swal.fire({
              title: "SUCCES!",
              text: "Action réussie",
              icon: "success"
            });
        }
     
        
    } catch (error) {
  console.log("error", error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur s'est produite, veuillez réessayer",
          
        });
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "format email non valide",
        
      });
    }
    
}

const deleteDirection = async (id) => {

  try {
    // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)

    // Affichez une confirmation à l'utilisateur avant la suppression réelle (en utilisant une bibliothèque comme Swal)
    const confirmResult = await Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas annuler cette action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OUI, supprimer !",
      cancelButtonText: "NON !"
    });

    if (confirmResult.isConfirmed) {

      // Supprimez réellement la catégorie
     const retour =  await deleteData(id).unwrap();

      // Affichez une notification de succès
      Swal.fire({
        title: "SUCCES!",
        text: "Action réussie",
        icon: "success"
      });
    }
  } catch (error) {
    console.error("Erreur lors de la suppression :", error);

    // Affichez une notification d'erreur
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.data.message,
      
    });
  } finally {
    // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)
  }
};

  switchSkin(skin);

  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de creation des opérateurs"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  } 
  const user = useSelector(getUserInfo);  
  useEffect(() => {
    createLog()   
  }, []);
    
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><NavLink to="" >Administration</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">Opérateurs</li>
            </ol>
            <h4 className="main-title mb-0">Gestion des Opérateurs</h4>
          </div>

        </div>


        <div className="container_liste_form">

<div className="first_container">
<span className="titre_cat">Liste des opérateurs</span>

<hr style={{width: '100%'}} />

<TableOperateur
deleteDirection={deleteDirection}
operateur={operateur}
setForm={setForm}
setLocalDate={setLocalDate}
/>

</div>





<div className="second_container">
<span className="titre_cat">Nouvel opérateur</span>

<hr style={{width: '100%'}} />

<div className="container_form">

<div className="container_border">


<div className="sous_container_form">

 

  <div className="container_label">
  <Form.Label htmlFor="exampleFormControlInput1">
             Nom :
            </Form.Label>
  </div>
  <div style={{width:'70%'}}>
  <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="Nom"
              value={form.nom_operateur}
              onChange={e => changeForm('nom_operateur', e.target.value)}
              
            />
  </div>
           
</div>
<div className="sous_container_form mt-3">

 

  <div className="container_label">
  <Form.Label htmlFor="exampleFormControlInput1">
             Téléphone :
            </Form.Label>
  </div>
  <div style={{width:'70%'}}>
  <Form.Control
              type="number"
              id="exampleFormControlInput1"
              placeholder="teléphone"
              value={form.num_operateur}
               onChange={e => changeForm('num_operateur', e.target.value)}
              
            />
  </div>
           
</div>
<div className="sous_container_form mt-3">

 

  <div className="container_label">
  <Form.Label htmlFor="exampleFormControlInput1">
             Email :
            </Form.Label>
  </div>
  <div style={{width:'70%'}}>
  <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="email"
              value={form.email_operateur}
              onChange={e => changeForm('email_operateur', e.target.value)}
              
            />
  </div>
           
</div>

<div className="sous_container_form mt-3">

 

  <div className="container_label">
  <Form.Label htmlFor="exampleFormControlInput1">
  Type :
            </Form.Label>
  </div>
  <div style={{width:'70%'}}>
  <Form.Select aria-label="Default select example"
 onChange={e => changeForm('id_type_op', e.target.value)}
  >

{/* {
  form.id_type_op ?
  
  typeOperateur.map((item)=>{  
    if(item.id_type_op == form.id_type_op){
     return <option value={item.id_type_op}>{item.lib_type_op}</option>
    }
   
  })

  :
  <option value="">Choisir Type opérateur</option>
  
  } */}
{form.id_type_op == "" && <option>Choisir</option>}
     {
         typeOperateur.map((item)=>(
             <option selected={item.id_type_op == parseInt(form.id_type_op)} value={item.id_type_op}>{item.lib_type_op}</option>
         ))
     }
 </Form.Select>
  </div>
           
</div>

<div className="sous_container_form mt-3">

 

  <div className="container_label">
  <Form.Label htmlFor="dt">
             Date autorisation :
            </Form.Label>
  </div>
  <div style={{width:'70%'}}>
  <Form.Control
              type="date"
              id="dt"
              value={localDate}
              onChange={e => setLocalDate(e.target.value)}
              
            />
  </div>
           
</div>

<div className="container_btn">
  <Button  variant="primary" onClick={()=> window.location.reload()}>
                  Annuler
                </Button>

                <Button  variant="danger" onClick={()=>save()}>
                  Enregister
                </Button>
  </div>

  </div>
</div>



</div>

    
</div>
         
                    
                        <Footer />
                      </div>
                    </React.Fragment>
                    )
}