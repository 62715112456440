import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import {
  useUpdatePlainteStatutMutation,
  useGetObsPlaintesQuery,
  useGetResultatObsQuery,
  useGetDecisionPlainteQuery,
  useCreateVerdicteMutation,
  useGetVerdicteParPlainteQuery,
  useUpdateVerdicteMutation,
  useCreateClotureAvisMutation,
  useGetBackInstQuery
} from "../../redux/apiSlice/plainteApiSlice.js";
import Swal from "sweetalert2";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice.js";
import Rapport from "./Rapport";

const RapportViewer = ({ mycomplaint, display3, handleClose3 }) => {
  const {
    data: lesDecision,
    refetch: refecthDecision,
    isLoading: loadDecision,
  } = useGetDecisionPlainteQuery();

  const [createVerdicte] = useCreateVerdicteMutation();
  const [createClotureAvis] = useCreateClotureAvisMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const { data: repererVerdictePlainte=[], refetch: refecthVerdictPlainte } =
    useGetVerdicteParPlainteQuery({ id_plt: mycomplaint?.id_plt });
    const {data: backInstruction = [], isLoading: loadBackIns} = useGetBackInstQuery(mycomplaint?.id_plt)
  const [updateVerdicte, {isLoading: loadUpdate}] = useUpdateVerdicteMutation();

 
  const [deci, setDeci] = useState([]);
  const [deciReserv, setDeciReserv] = useState([]);
  const [comtaire, setComtaire] = useState("");
  const [objSuivi, setObjSuivi] = useState(false);
  const [objRedac, setObjRedac] = useState({id : "", text :""});
  const [avis, setAvis] = useState(null);
  const [images, setImages] = useState([]);
  const [gestUpRoute, setGestUpRoute] = useState(0);

  const [showComment, setShowComment] = useState(false);


  const handleShowComment =(id)=>{
    setObjRedac({...objRedac, id_dec : parseInt(id) })
    setShowComment(true)
  }


  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    setImages(selectedFiles);
  };

  const handleCloseComment =()=>setShowComment(false)

  const {
    data: obsPlainte,
    refetch: refecthObs,
    isLoading: loadObs,
  } = useGetObsPlaintesQuery(mycomplaint?.id_plt);
  const [updatePlainteStatut, { isLoading: loadUpdateState }] =
    useUpdatePlainteStatutMutation();
  const {
    data: resultObs,
    refetch: refecthresultObs,
    isLoading: loadresultObs,
  } = useGetResultatObsQuery(mycomplaint?.id_plt);

  useEffect(() => {
    refecthObs();
    refecthresultObs();
    const generatedPdfContent = (
      <Rapport
        obsPlainte={obsPlainte}
        resultObs={resultObs}
        mycomplaint={mycomplaint}
      />
    );
  }, [mycomplaint?.id_plt]);

  useEffect(() => {
  
  }, [repererVerdictePlainte]);

  useEffect(() => {
  
  }, [backInstruction]);

  const createLogActionPlainte = async (status)=>{
    try {
      await createLogPage({content: status}).unwrap();    
  } catch (error) {
    console.log('error', error)
  }
  }
  const uploaad = () => {
    handleClose3()
    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
        
          const formData = new FormData();
          
          const select = deci.filter((item)=>(item.statut == true))
          const data = select.map((item)=>{
            if (item.comment == "") {
              return{
                id_dec : item.id_dec,
                commentaire : null
              }
            } else {
              return{
                id_dec : item.id_dec,
                commentaire : item?.comment
         
              }
            }
          })

      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }

          if (repererVerdictePlainte.length == 0) {
           
              const objetSend = {
                id_plt: mycomplaint?.id_plt,
                suivi : objSuivi,
                dec: JSON.stringify(data),
                type_verdict : 0
              }
                  Object.keys(objetSend).forEach((key) => {
                      formData.append(`${key}`, objetSend[key]);
                  });
      
                 const aa = await createVerdicte(formData);
                 createLogActionPlainte("Proposition d'action par l'instructeur")

           
          } else{ 

            if (mycomplaint?.id_stat_plt === 9) {

        
               const objetSend = {
                  id_plt: mycomplaint?.id_plt,
                  suivi : objSuivi,
                  dec: JSON.stringify(data),
                  type_verdict : 1
                }
              
                Object.keys(objetSend).forEach((key) => {
                  formData.append(`${key}`, objetSend[key]);
              });
              const retourAvisComite = await createVerdicte(formData);
              createLogActionPlainte("Proposition d'action par le comité")
              
    
              if (retourAvisComite && avis != null) {
                const rr = await createClotureAvis({content : avis, id_plt :  mycomplaint?.id_plt});
               createLogActionPlainte("Avis du comité")

              
                
              }   
               
                
                
            }  else {     
        
const objetSend = {
                  id_plt: mycomplaint?.id_plt,
                  suivi : objSuivi,
                  dec: JSON.stringify(data),
                  type_verdict : 0
                }

                console.log("objetSend", objetSend)
                    Object.keys(objetSend).forEach((key) => {
                        formData.append(`${key}`, objetSend[key]);
                    });
                   

                    // let formDataObject = {};
                    // for (let pair of formData.entries()) {
                    //     formDataObject[pair[0]] = pair[1];
                    // }
                    // console.log("formDataObject", formDataObject)

             await updateVerdicte({id : mycomplaint?.id_plt, formData}).unwrap();

  
                   createLogActionPlainte("Proposition d'action par l'instructeur après renvoie en instruction")
           
            }
            }
      

          const retour_plainte2 = await updatePlainteStatut({
            id_stat_plt: mycomplaint?.id_stat_plt === 9 ? 10 : 9,
            id_plt: mycomplaint?.id_plt,
          }).unwrap();

            window.location.href = "/toutes_les_plaintes";
        
            // console.log("retour_plainte2", retour_plainte2)
      
        }
      });
    } catch (error) {
      console.log("error",error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur s'est produite, veuillez réessayer"
    });
    }
  };

  const checkAction =(id, value)=>{
 
    
if (value == true || value == false) {
  const objet = deciReserv.map((item)=>{

    if (item.id_dec == id) {
      return{...item, statut : value}
    } else {
      return item
    }
  })

setDeci(objet)
setDeciReserv(objet)
} else {

  const objet = deciReserv.map((item)=>{

    if (item.id_dec == id) {
      return{...item, comment : value}
    } else {
      return item
    }
  })

setDeci(objet)
setDeciReserv(objet)
handleCloseComment()
setObjRedac({id : "", text :""})
}

  }


  useEffect(() => {
    if (lesDecision) {
    const devF = lesDecision.map((item)=>{
     return {
       ...item, 
       statut : false,
       comment : ""
     }
    })
 
    setDeci(devF)
    setDeciReserv(devF)
    }
    
   }, [lesDecision]);
  return (
    <>
    <Modal show={display3} onHide={handleClose3} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {
            mycomplaint?.id_stat_plt === 9 ?
            "Recommandation du conseil"
            :
            "Sélectionner une recommandation à l'endroit du comité de gestion"

          }
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>

     
      { deci.map((item)=>{

                  return(
        <Row className="g-2 mb-5" key={item.id_dec}>

<Col sm="1" xl="1">
  
           </Col>
                
          <Col sm="5" xl="5">
 
<Form.Check type="checkbox" 
checked={item.statut} 
label={item.lib_dec} 
onChange={(e)=>{checkAction(item.id_dec, e.target.checked)}}

/>
           
          </Col>

          <Col sm="6" xl="6">   
           {
            item.statut == true &&  mycomplaint?.id_stat_plt != 9 &&

            <>
            <Form.Control
           
            type="text"
            value={item.comment}
            disabled
        />
            <span style={{textDecorationLine : 'underline', cursor: 'pointer', color: 'blue'}}
            onClick={()=>handleShowComment(item.id_dec)}
            >
            Commenter
            </span>
            </>
            }
                      
          </Col>
         
        </Row>
        )
                })
              }

              <Row className="mb-5">
                <Col lg="1"></Col>
                <Col lg="10">
                <Form.Label htmlFor="img">
                {mycomplaint?.id_stat_plt != 9 ?"Charger le rapport d'instruction" : "Pièces Jointes"} 
          </Form.Label>
          <Form.Control
            onChange={handleFileChange}
            type="file"
            id="img"
            placeholder=""
            name="images"
            multiple
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
              padding: "8px"
            }}
          />
                </Col>
                <Col lg="1"></Col>

              </Row>

{
        mycomplaint?.id_stat_plt != 9 &&
        <>
        <Row className="" >
      <Form.Label>La plainte nécéssite t-elle un suivi ultérieure ?</Form.Label>
      </Row>
      <Row className="mb-5" >

       <Col sm="5" xl="5">
       
<Form.Check type="radio" 
checked={objSuivi === false } 
label="Non"
onChange={()=>setObjSuivi(false)}
/>
         
          </Col>

          <Col sm="6" xl="6">   
          <Form.Check type="radio" 
checked={objSuivi === true }
label="Oui"
onChange={()=>setObjSuivi(true)}
/>       
          </Col>
         
        </Row>
        </>
        }
 {
  mycomplaint?.id_stat_plt === 9 &&
  <Row className="mt-5" >
 <Form.Label htmlFor="exampleFormControlTextarea1">
           Avis du conseil
          </Form.Label>
          <Form.Control
            onChange={(v)=>setAvis(v.target.value)}
            as="textarea"
            value={avis}
            id="exampleFormControlTextarea1"
            rows="8"
            placeholder="Entrer votre commentaire..."
          ></Form.Control>
      </Row>}
        <Row className="mt-5">
        <Col >
           
          </Col>
        <Col className="text-end" >
            <Button variant="success" onClick={()=>uploaad()}>
              { mycomplaint?.id_stat_plt === 9 ? 'Clôturer la plainte' : 'Valider'}
            </Button>
          </Col>
        </Row>

       
      </Modal.Body>
    </Modal>

    <Modal show={showComment} onHide={handleCloseComment} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Commentaire </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            <Col sm="12" xl="12">
              
            <Form.Label htmlFor="cmt">
          
          </Form.Label>
          <Form.Control
            onChange={(v)=>setObjRedac({...objRedac, text :v.target.value })}
            as="textarea"
            value={objRedac.text}
            id="cmt"
            rows="2"
            placeholder="Entrer votre commentaire..."
          ></Form.Control>

              
            </Col>
            
          </Row>
          <Row >
            
            
          </Row>
          <Row className="mt-2">
            <Col >
              
            </Col>
            <Col className="d-flex flex-row justify-content-end gap-2">
            <Button variant="secondary" onClick={handleCloseComment}>
                Annuler
              </Button>
              <Button variant="success" onClick={()=>checkAction(objRedac.id_dec, objRedac.text)}>
                Valider
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    

    </>
  );
};

export default RapportViewer;
