import React from "react";
import "./stylePlainte.css";
import moment from "moment";
import { Stack } from "rsuite";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "primereact/button";
import { Tooltip as Tol, OverlayTrigger } from "react-bootstrap";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice";


function TableDetailsAutresReq({ mycomplaint }) {
  // console.log("mycomplaint", mycomplaint)
  const { data: OperateurTab = [] } = useGetOperateursQuery()

  const operateur = OperateurTab.find((item) => (item?.id_operateur == parseInt(mycomplaint?.id_operateur)))


  const titreStyle = {
    fontSize: "1.7em", // Taille de la police
    fontWeight: "bolder", // Gras
    // color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    // fontFamily: "EB Garamond",

  };

  const soustitreStyle = {
    fontSize: "1.4em", // Taille de la police
    fontWeight: "bolder", // Gras
    // color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    //  fontFamily: "EB Garamond",
  };

  const libelleStyle = {
    fontSize: "0.9em", // Taille de la police
    fontWeight: "bolder", // Gras
    // color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    // backgroundColor: 'rgba(31,90,146, 0.4)'
    // fontFamily: "EB Garamond",
  };

  const dataStyle = {
    fontSize: "0.9em", // Taille de la police
    color: "#000", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    // backgroundColor: 'rgba(31,90,146, 0.4)'
    // fontFamily: "EB Garamond",
  };

  const formatDate = (data) => {
    const timestamp = typeof data === "string" ? parseInt(data, 10) : data;

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      console.error("Invalid Date:", timestamp);
      return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();

    return formattedDate;
  };

  const trySetFont = (doc, font, style) => {
    // Vérifier si la police est disponible
    const fontList = doc.getFontList();
    if (fontList && fontList[font]) {
      doc.setFont(font, style);
    } else {
      // Si la police n'est pas disponible, utiliser une alternative comme "Helvetica"
      doc.setFont("Helvetica", style);
    }
  };
  const generatePDF = () => {
    const doc = new jsPDF();
    const tempDoc = new jsPDF();

    // Ajouter une image

    doc.addImage(
      "https://apisymfonypigru.leavesmartconsulting.com/public/logo_pdf.jpg",
      "JPEG",
      10,
      8,
      40,
      40
    );

    tempDoc.setFillColor(26, 88, 143);
    tempDoc.rect(90, 30, 90, 100, "F"); // Coordonnées x, y, largeur, hauteur, style de remplissage ('F' signifie remplissage)

    // Texte "FICHE DE PLAINTE" en blanc

    tempDoc.setTextColor(255, 255, 255);
    tempDoc.setFontSize(10);
    tempDoc.setFillColor(26, 88, 143);
    tempDoc.rect(90, 30, 90, 100, "F");
    tempDoc.text("FICHE DE REQUETE", 86, 40);
    trySetFont(doc, "Garamond", "bold");;

    doc.text("FICHE DE REQUETE", 86, 36);

    // Titre du PDF
    doc.setFontSize(10);
    trySetFont(doc, "Garamond", "bold");;
    doc.text("FICHE DE REQUETE", 20, 55);

    // Générer le tableau dans le PDF

    const columnStyles = { 0: { cellWidth: 70 }, 1: { cellWidth: 115 } }

    doc.autoTable({
      body: [
        ["Référence de la plainte chez l'opérateur", mycomplaint?.ref_plt],
        ["Réponse de l'opérateur", mycomplaint?.recours === "0" ? "Non (moins d'un mois)" : "Oui mais pas satisfait(e)"],
        ["Opérateur concerné", mycomplaint?.Operateur?.nom_operateur],
        ["Type Opérateur concerné", operateur?.Type_operateur?.lib_type_op],

        ["Canal de réception", mycomplaint?.Canal_plainte?.lib_canal],
      ],
      columnStyles,
      startY: 60,
      theme: "grid",
      styles: { cellPadding: 2, fontSize: 9, halign: "left" },
      didParseCell: function (data) {
        if (data.row.section === 'body' && data.column.index === 0) {
          // Appliquer le style gras uniquement pour la première colonne du corps du tableau
          data.cell.styles.fontStyle = 'bold';
          doc.setFillColor(255, 0, 0, 0.2);
        }
      },
    });

    // Titre du tableau 2
    trySetFont(doc, "Garamond", "bold");;
    doc.text("INFORMATIONS SUR LE PLAIGNANT", 20, doc.autoTable.previous.finalY + 13);

    doc.autoTable({
      body: [
        ["Nature du plaignant:", mycomplaint.type_personne === 0 ? "Personne physique" : "Personne morale"],
        ["Raison sociale:", mycomplaint?.type_personne !== "0" ? mycomplaint?.raison : "N/A"],
        ["Nom du plaignant:", mycomplaint?.nom_plt],
        ["Prénom(s) du plaignant:", mycomplaint?.prenom_plt],
        ["Sexe du plaignant:", mycomplaint?.sexe === "F" ? "Féminin" : "Masculin"],
        ["Téléphone:", mycomplaint?.tel_plt],
        ["Email:", mycomplaint?.email_plt],
        ["Adresse de résidence:", mycomplaint?.adresse_plt],
        ["Profession:", mycomplaint?.profession],
        ["Nationalité:", mycomplaint?.nationalite],
      ],
      columnStyles,
      startY: doc.autoTable.previous.finalY + 20,
      theme: "grid",
      styles: { cellPadding: 2, fontSize: 9, halign: "left" },
      didParseCell: function (data) {
        if (data.row.section === 'body' && data.column.index === 0) {
          // Appliquer le style gras uniquement pour la première colonne du corps du tableau
          data.cell.styles.fontStyle = 'bold';
          doc.setFillColor(255, 0, 0, 0.9);
        }
      },
    });

    // Titre du tableau 3
    const formateDatePlainte = (laDate) => {
      const dateObject = new Date(laDate);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    };

    trySetFont(doc, "Garamond", "bold");;
    doc.text("DETAIL DE LA REQUETE", 20, doc.autoTable.previous.finalY + 10);

    doc.autoTable({
      body: [
        ["Résumé de la plainte:", mycomplaint?.desc_plt],
        ["Solution attendue:", mycomplaint?.Solution_attendue?.lib_solution],
        ["Date du premier constat:", mycomplaint?.date_constat == null ? "-" : formateDatePlainte(mycomplaint?.date_constat)],
        ["Canal de retour souhaité:", mycomplaint?.canal_retour],
      ],
      columnStyles,
      startY: doc.autoTable.previous.finalY + 20,
      theme: "grid",
      styles: { cellPadding: 2, fontSize: 9, halign: "left" },
      didParseCell: function (data) {
        if (data.row.section === 'body' && data.column.index === 0) {
          // Appliquer le style gras uniquement pour la première colonne du corps du tableau
          data.cell.styles.fontStyle = 'bold';
          doc.setFillColor(255, 0, 0, 0.2);
        }
      },
    });

    // Sauvegarder le PDF
    doc.save("informations_requêtes.pdf");
  };
  return (
    <div className="container_tab_details">
      {/* <h3 style={titreStyle}>INFORMATIONS DE REQUETE</h3> */}

      <div className="container row">
        <div className="col"></div>
        <div className="col"></div>
        <div className="col text-end">
          {/* <button
        className="m-btn m-btn-border cta__btn active m-btn-border-3"
        onClick={generatePDF}
        style={{}}
      >
        Générer PDF
      </button> */}
          <OverlayTrigger

            placement={"top"}
            overlay={
              <Tol>
                Exportation PDF.
              </Tol>
            }
          >
            <Button
              type="button"
              icon="pi pi-file-pdf"
              rounded
              onClick={generatePDF}
              className=""
              data-pr-tooltip="PDF"
              style={{ borderRadius: 30 }}
              severity="danger"
            />
          </OverlayTrigger>


        </div>
      </div>

      <div className="container row p-3">
        <div className="col-7">
          <span style={soustitreStyle}> Numéro de suivi :</span>
          <span style={{ fontSize: 18, color: "#000" }}> {mycomplaint.num_suivi_plt}</span>

        </div>
        <div className="col-4 text-right" >
          <span style={soustitreStyle}>Date de soumission :</span>
          <span style={{ fontSize: 18, color: "#000" }}>{" "}{formatDate(mycomplaint.date_plt)}</span>
        </div>
      </div>

      <div className="container row">
        <div className="col-lg-12 col-md-12">
          <h6 className="" style={soustitreStyle}>
            Préalables sur la requête
          </h6>

          <table class="table table-bordered border-primary">
            <tr>
              <td class="border col-4 " style={libelleStyle}>
                Référence de la plainte chez l’opérateur :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.ref_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4  " style={libelleStyle}>
                Réponse de l’opérateur :
              </td>
              <td class="border col-7" style={dataStyle}>

                {mycomplaint?.repondu == 1 ? "Oui mais pas satisfait(e)" : "Non"}
              </td>
            </tr>

            <tr>
              <td class="border col-4" style={libelleStyle}>
                Opérateur concerné :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.Operateur?.nom_operateur}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Type Opérateur concerné :
              </td>
              <td class="border col-7" style={dataStyle}>
                {operateur?.Type_operateur?.lib_type_op}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Canal de réception
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.Canal_plainte?.lib_canal}
              </td>
            </tr>

          </table>
        </div>

        <div className="col-lg-12 col-md-12">
          <h6 style={soustitreStyle}>Informations sur le plaignant</h6>

          <span style={libelleStyle}>
            {mycomplaint?.type_personne === 0
              ? "Personne physique"
              : "Personne morale"}
          </span>

          <table class="table table-bordered border-primary">
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Raison sociale
              </td>

              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.raison != null ? mycomplaint.raison : "-"}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Nom du plaignant :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.nom_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Prénoms du plaignant :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.prenom_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Téléphone :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.tel_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Email :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.email_plt == null ? "-" : mycomplaint?.email_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Adresse de résidence :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.adresse_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Profession :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.profession}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Nationalité:
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.nationalite}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="container row">
        <div className="col-lg-12 col-md-12">
          <h6 style={soustitreStyle}>Détails de la requête</h6>

          <table class="table table-bordered border-primary">
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Résumé de la plainte :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.desc_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Solution attendue :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.Solution_attendue?.lib_solution}
              </td>
            </tr>
            {/* <tr>
              <td class="border col-4" style={libelleStyle}>
                Date du premier constat :
              </td>
               <td class="border col-7" style={dataStyle}>
                {mycomplaint.date_constat == "null" ? "-" : moment(mycomplaint.date_constat).format("DD/MM/YYYY")}
              </td>
            </tr> */}
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Canal de retour souhaité :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.canal_retour === "email" ? "E-mail" : "Téléphone"}
              </td>
            </tr>
          </table>
        </div>

        {(mycomplaint?.pieces_plaintes && mycomplaint.pieces_plaintes.length > 0) &&
          <div className="col-lg-12 col-md-12">
            <h6 style={soustitreStyle}>Pièces Jointes</h6>

            <Stack direction="row" spacing={5}>
              {mycomplaint.pieces_plaintes.map((piece, index) => (
                <a key={index} target="_blank" href={`https://apisymfonypigru.leavesmartconsulting.com/public/${piece.nom_piece}`}>
                  Pièce Jointes {++index}
                </a>
              ))}
            </Stack>
          </div>
        }

      </div>
    </div>
  );
}

export default TableDetailsAutresReq;
