import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import Sidebar from "../layouts/Sidebar";
import { useSelector } from "react-redux";
import { getUserInfo } from "../redux/slice/userSlice";

import DashCards from "./DashCards.js";
import { Skeleton } from "@mui/material";
import "./styleDash.css";
import moment from "moment";
import {
  useGetLesObligationsAffecteeQuery,
  useGetObligationsParTypOpQuery,
  useGetTypeOperateurQuery,
} from "../redux/apiSlice/statistiquePlainteApiSlice.js";
import { useCreateLogPageMutation } from "../redux/apiSlice/logPageApiSlice.js";
import TableClassification from "../pages/statistique/obligation/dataTables/TableClassification.js";
import ShieldIcon from "@rsuite/icons/Shield";
import PageIcon from "@rsuite/icons/Page";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import DeviceIcon from "@rsuite/icons/Device";
import TextImageIcon from "@rsuite/icons/TextImage";

export default function DashboardObligation() {
  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user || !user.id) {
      window.location.href = "/";
    }
  }, [user]); 
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({
          content: "Dashboard obligation règlémentaire",
        }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);

  // console.log("liste",listePlainte)
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const { data: typeOp } = useGetTypeOperateurQuery();

  const recupeIdFrsAccesInternet = () => {
    const table_typ_op = typeOp
      ?.filter((typ) =>
        ["fournisseur d'accès internet"].includes(
          typ?.lib_type_op.toLowerCase()
        )
      )
      .map((typ) => typ?.id_type_op);
    if (table_typ_op && table_typ_op?.length > 0) return table_typ_op[0];
    return 0;
  };

  const { data: obligationAffectee } = useGetLesObligationsAffecteeQuery();
  const initOb = {
    id_cat_obligation_reg: "",
    id_operateur: "",
    id_type_ob: "",
    id_type_op: recupeIdFrsAccesInternet(),
    id_direction: "",
  };
  const [lesOb, setLesOb] = useState({ ...initOb });
  const [dataOb, setDataOb] = useState([]);

  const { data: obParTypOp } = useGetObligationsParTypOpQuery({
    id_type_op: lesOb.id_type_op !== 0 ? lesOb.id_type_op : 2,
  });

  const nbreObligationRespectee = (tableau, statut) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter((ob) => ob.statut === statut).length;
    }
    return 0;
  };

  const comparerDate = (dateVigeur, periodicite) => {
    const dateFuture = moment(dateVigeur)
      .add(parseInt(periodicite), "days")
      .format("YYYY-MM-DD");
    const dateAujourdhui = moment().format("YYYY-MM-DD");
    return moment(dateFuture).isBefore(dateAujourdhui);
  };

  const obligationEnCours = (tableau) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter(
        (ob) =>
          comparerDate(
            ob?.date_vigueur,
            ob?.Obligation_reg?.Periodicite?.lib_periode
          ) === true
      ).length;
    }
    return 0;
  };

  const recupererInfo = (champ, value) => {
    setLesOb({ ...lesOb, [champ]: value });
  };
  let nbreObLieeTypOp = obParTypOp?.reduce(
    (total, op) => total + op?.Obligation_operateurs.length,
    0
  );

  const classementOp = () => {
    const infosSurObligationOp = obParTypOp?.map((valeur) => {
      let cumulePoid = valeur?.Obligation_operateurs?.reduce(
        (total, poid) => total + poid?.Obligation_reg?.poids,
        0
      );
      const resultatsDenominateurTauxConformite =
        nbreObLieeTypOp - obligationEnCours(valeur?.Obligation_operateurs);
      const tauxConformitee =
        resultatsDenominateurTauxConformite > 0
          ? (
              (nbreObligationRespectee(valeur?.Obligation_operateurs, 0) /
                resultatsDenominateurTauxConformite) *
              100
            ).toFixed(2)
          : 0;
      return {
        nom_operateur: valeur?.nom_operateur,
        nbre_totale_ob: nbreObLieeTypOp,
        nbre_ob: valeur?.Obligation_operateurs?.length,
        ob_resp: nbreObligationRespectee(valeur?.Obligation_operateurs, 1),
        ob_non_resp: nbreObligationRespectee(valeur?.Obligation_operateurs, 0),
        ob_en_cours: obligationEnCours(valeur?.Obligation_operateurs),
        nbre_total_poids: cumulePoid,
        taux_conformitee: tauxConformitee,
      };
    });
    const nonZeroObOperators = infosSurObligationOp?.filter(
      (op) => op.nbre_ob !== 0
    );

    nonZeroObOperators?.sort((a, b) => {
      // if (a.ob_resp === b.ob_resp) {
      //   if (a.nbre_ob === b.nbre_ob) {
      //     return a.nom_operateur.localeCompare(b.nom_operateur);
      //   }

      //   return a.nbre_ob - b.nbre_ob;
      // }
      if (a.nbre_total_poids === b.nbre_total_poids) {
        return b.ob_resp - a.ob_resp;
      }

      return b.nbre_total_poids - a.nbre_total_poids;
    });

    const updatedInfosSurObligationOp = nonZeroObOperators?.map((op, index) => {
      const suffix = index === 0 ? "er" : "em";
      return {
        ...op,
        rang: `${index + 1}${suffix}`,
      };
    });
    if (updatedInfosSurObligationOp?.length > 0)
      setDataOb(() => [...updatedInfosSurObligationOp]);
    else setDataOb([]);
  };
  useEffect(() => {
    classementOp();
  }, [obligationAffectee, obParTypOp]);
  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-2">
        <div className="d-md-flex flex-column">
          <h4 className="main-title mb-0">Tableau de Bord</h4>
        </div>

        <Row>
          <Col sm="12" xl="12" className="d-flex flex-column">
            <span className="titre_compatiment">
              Classement des opérateurs-Obligations réglementaires
            </span>
          </Col>
          <Col sm="12" xl="12" className="d-flex flex-column mb-2">
            <Form.Label htmlFor="typeOb">Type d'operateur:</Form.Label>

            <Form.Select
              id="typeOp"
              value={lesOb.id_type_op}
              onChange={(e) =>
                recupererInfo("id_type_op", parseInt(e.target.value))
              }
              style={{ width: "400px" }}
            >
              <option value="">Choisir</option>
              {typeOp?.map((typOp, key) => (
                <option key={key} value={typOp.id_type_op}>
                  {typOp.lib_type_op}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col sm="12" xl="12" className="d-flex flex-column">
            <TableClassification ob={dataOb} />
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
