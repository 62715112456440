import { apiSlice } from "../api/apiSlice";

export const catPrestationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCatPrestation: builder.query({
      query: () => ({
        url: "/categorie_prestation",
        method: "GET",
      }),
    }),
    createCatPrestation: builder.mutation({
      query: (body) => ({
        url: "/categorie_prestation/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CategoriePrestation"],
    }),
    updateCatPrestation: builder.mutation({
      query: (body) => ({
        url: `/categorie_prestation/update/${body.id_cat_prest}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["CategoriePrestation"],
    }),
    deleteCatPrestation: builder.mutation({
      query: (body) => ({
        url: `/categorie_prestation/del/${body.id_cat_prest}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["CategoriePrestation"],
    }),
  }),
});

export const {
  useGetCatPrestationQuery,
  useCreateCatPrestationMutation,
  useUpdateCatPrestationMutation,
  useDeleteCatPrestationMutation,
} = catPrestationApiSlice;
