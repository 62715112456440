// import React from "react";
// import { Link } from "react-router-dom";



// // eslint-disable-next-line
// export default Notification = [
//   {
//     "avatar": (<i className={Img1}></i>),
//     "text": (<div style={{
//       display : 'flex',
//       flexDirection : 'row',
//       justifyContent :'space-between'
//     }}><strong>Notifications Plaintes</strong> 
//     <div style={{
//       width : 20,
//       height : 20,
//       borderRadius : 20,
//       background : 'red',
//       color : 'white',
//       display : 'flex',
//       justifyContent : 'center',
//       alignItems : 'center'
//     }}>5</div>
//     </div>),
    
//   },
//   {
//     "avatar": (<i className={Img2}></i>),
//     "text": (<React.Fragment>{" "}<strong>Notifications obligations</strong></React.Fragment>),
//     "date": "Aug 18 10:30am",
//     "status": "online"
//   },
  
  
  
// ];

import React, {useState} from 'react'
import { Modal, Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table'
export default function Notification({plt, recep, instru, notifyObligation}) {
  const navigate = useNavigate()
   // modal 
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
 
  const Img1 = "ri-hard-drive-2-line";
  const Img2 = "ri-pie-chart-2-line";

  const checkLink = ()=>{
    // navigate("/accuser_reception")
  }
  return (
    <div style={{
      width : '100%',
      display : 'flex',
      flexDirection : 'column',
      alignItems : 'center'
      
    }}>
      
      <div style={{
      width : '100%',
      display : 'flex',
      flexDirection : 'row',
      justifyContent : 'flex-start',
      gap: 20,
      marginTop :15,
      cursor : 'pointer',
      paddingLeft :15,
      
    }}
    onClick={()=>handleShow()}
    >
 <i className={Img1}></i>
 <strong>Notifications Plaintes</strong>
 <div style={{
      width : 20,
      height : 20,
      borderRadius : 20,
      background : 'red',
      color : 'white',
      display : 'flex',
      justifyContent : 'center',
      alignItems : 'center'
    }}>{plt}</div>
        
      </div>
<hr style={{width : '100%'}}/>

<div style={{
      width : '100%',
      display : 'flex',
      flexDirection : 'row',
      justifyContent : 'flex-start',
      gap: 20,
      marginTop :8,
      cursor : 'pointer',
      paddingLeft :15,
      
    }}
    onClick={()=>navigate("/suivi-obligation-reglmt")}
    >
 <i className={Img2}></i>
 <strong>Notifications Obligations</strong>
 <div style={{
      width : 20,
      height : 20,
      borderRadius : 20,
      background : 'red',
      color : 'white',
      display : 'flex',
      justifyContent : 'center',
      alignItems : 'center'
    }}
   
    >{notifyObligation}</div>
        
      </div>


      <hr style={{width : '100%'}}/>


              {/* ----------------modal de modification---------- */}
              <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>NOTIFICATIONS PLAINTES</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
            

<Table bordered hover>
      
      <tbody>
        <tr onClick={()=>navigate("/accuser_reception")} style={{cursor : 'pointer'}}>
        <td className='col-8' style={{cursor : 'pointer'}}>Plaintes non réceptionnées depuis 72h</td>
        <td className='col-4'><Badge bg="success">
        {recep?.nb_plt}
                      </Badge></td>
        </tr>
        <tr onClick={()=>navigate("/plainte_receptionee")} style={{cursor : 'pointer'}}>
        <td className='col-8'>Plaintes non instruites depuis 5 jours</td>
        <td className='col-4'><Badge bg="success">
        {instru?.nb_plt}
                      </Badge></td>
        </tr>
        <tr>
        {/* <td className='col-8'>Plainte en complétude, information fournit depuis plus de 25 jours </td>
        <td className='col-4'>25 plaintes</td> */}
        </tr>
      </tbody>
    </Table>

          </div>
                   </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Fermer
                  </Button>
                 
                </Modal.Footer>
              </Modal>

    </div>
  )
}


