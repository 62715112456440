import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableParamEmail from "./dataTable/TableParamEmail";
import Swal from "sweetalert2";
import {
  useGetParamEmailQuery,
  useUpdateParamEmailMutation,
} from "../../redux/slice/paramEmailApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";
export default function ParamEmail() {
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const { data: paramEmail } = useGetParamEmailQuery();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const init = {
    lib_email: "",
  };
  const [form, setForm] = useState({ ...init });

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // const { data: paramEmail = [], isLoading: loadParamEmail } =
  //   useGetParamEmailQuery();

  const [updateData, { isLoading: loadUpdate }] = useUpdateParamEmailMutation();

  const changeForm = (field, value) => setForm({ ...form, [field]: value });

  const save = async () => {
    try {
      if (form?.id) {
        await updateData(form).unwrap();
        setForm({ ...init });
        Swal.fire({
          title: "Paramètrage Email!",
          text: "Modification réussie ",
          icon: "success",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.data.errors[0].message,
        footer: '<a href="#">essyer une autre?</a>',
      });
    }
  };

  
  
  switchSkin(skin);
  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de creation de paramètre d'email"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  } 
  const user = useSelector(getUserInfo);
  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard-obligation-reglmt">
                  Administration
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Paramètre d'Email
              </li>
            </ol>
            <h4 className="main-title mb-0">Gestion du Paramètrage</h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">Paramètre de l'Email</span>

            <hr style={{ width: "100%" }} />

            <TableParamEmail paramEmail={paramEmail} setForm={setForm} />
          </div>

          <div className="second_container">
            <span className="titre_cat">Modifier Email</span>

            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Adresse Email:
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Email"
                      onChange={(e) => changeForm("lib_email", e.target.value)}
                      value={form.lib_email}
                    />
                  </div>
                </div>
                <br></br>

                <div className="container_btn">
                  <Button
                    variant="primary"
                    onClick={() => setForm({ ...init })}
                  >
                    Annuler
                  </Button>

                  <Button variant="success" onClick={() => save()}>
                    Enregister
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
