import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  emailUser: null,
  nameUser: null,
  userId: null,
};

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_ID_USER: (state, action) => {
      // console.log(action.payload)

      const { userId } = action.payload;

      state.userId = userId;
    },
    SET_ACTIVE_USER: (state, action) => {
      const { emailUser, nameUser, userId } = action.payload;
      state.isLoggedIn = true;
      state.emailUser = emailUser;
      state.nameUser = nameUser;
      state.userId = userId;
    },
    REMOVE_ACTIVE_USER(state, action) {
      state.isLoggedIn = false;
      state.emailUser = null;
      state.nameUser = null;
      state.userId = null;
    },
  },
});

export const { SET_ID_USER, SET_ACTIVE_USER, REMOVE_ACTIVE_USER } =
  authSlice.actions;
export const selectUserID = (state) => state.user.userId;
export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectEmail = (state) => state.user.emailUser;
export const selectUserName = (state) => state.user.nameUser;
export default authSlice.reducer;
