import { apiSlice } from "../api/apiSlice"

export const suiviObligationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSuiviObligations: builder.query({
            query: () => ({
                url: '/obligation_affected',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'SuiviObligations', id: 'LIST' },
                    ...result.map(item => ({ type: 'SuiviObligations', id: item.id_ob_op }))
                ] : ['SuiviObligations']
            }
        }),

        // createTypeObligation: builder.mutation({
        //     query: body => ({
        //         url: '/obligation/type_obligation/create',
        //         method: 'POST',
        //         body
        //     }),
        //     invalidatesTags: ['TypeObligations']
        // }),

        updateSuiviObligation: builder.mutation({
            query: (id) => ({
                url: `/obligation/change_state_obligation_operateur/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: ['SuiviObligations']
        }),

        // deleteTypeObligation: builder.mutation({
           
        //     query: (id) => ({
        //       url: `/obligation/type_obligation/del/${id}`,
        //       method: 'DELETE',
        //     }),
        //     invalidatesTags: ['TypeObligations'],
        //   }),
    })
})

export const {
    useGetSuiviObligationsQuery,
    // useCreateTypeObligationMutation,
    useUpdateSuiviObligationMutation,
    // useDeleteTypeObligationMutation,
} = suiviObligationApiSlice