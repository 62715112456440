import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import { useGetSolutionQuery } from "../../redux/apiSlice/plainteApiSlice.js";


import Swal from "sweetalert2";

const Step2 = (props) => {

  const { dummyDatas ,setDummyData} = props;

  const { data: solutions = [], isLoading: loadSolution } =
  useGetSolutionQuery();

  const [show, setShow] = useState(false);
  const [stateSolutions, setStateSolutions] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true)

  const { plainteData, setPlainteData } = props;
  const changePlainteData = (field, value) => setPlainteData({ ...plainteData, [field]: value });
  const [errorResume, setResumeError] = useState()
  const [errorSltAtt, setSltAttError] = useState()
  const [gestSolution, setGestSolution] = useState(false)

  const today = new Date()

  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    changePlainteData("images", selectedFiles);
  };
  const validateNonEmpty = (value, setValidationError) => {
    const isValid = value.trim() !== "";
    setValidationError(isValid ? null : 'Champ requis');
    return isValid;
  };
  const changeDummyData = (field, value) =>
    setDummyData({ ...dummyDatas, [field]: value });

  const handleResumeChange = (e) => {
    const resume = e.target.value;
    validateNonEmpty(resume, setResumeError);
    changePlainteData('desc_plt', resume);
  };
  const handleSolutionAttendueChange = (e) => {
    const value = e.target.value
    validateNonEmpty(value, setSltAttError);
    changePlainteData('id_solution', parseInt(value));
    const soluFiltre = solutions.find((item)=>item.id_solution == value)
    changeDummyData("lib_solution", soluFiltre?.lib_solution)

  };

  const handleAutresSolutionChange = (e) => {
    const solution = e.target.value;
    changePlainteData('lib_solution', solution);
  };

  const controleChoixRetour= ()=>{
    if (plainteData.email_plt == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Veuillez renseigner d'abord votre email pour le choix de cette option"
    });
    } else {
      changePlainteData("canal_retour", "email")
    }
    
  }
  const chargeAutresSolution = (value)=>{
    if (value == true) {
      const newObjet = {...plainteData, lib_solution : "" , id_solution : 0}
      setPlainteData(newObjet)
      setGestSolution(value)
    } else {
      const newObject = { ...plainteData, id_solution : 0 }; 
      delete newObject["lib_solution"]; // Supprime la clé
      setPlainteData(newObject); 
      setGestSolution(value)
      
    }
  }
  useEffect(() => {
    if (solutions) {
      setStateSolutions(solutions
        .slice()
        .sort((a, b) =>
          a?.lib_solution.localeCompare(b?.lib_solution)
        ))
    }
   
  }, [solutions]);
  return (
    <div className="container  p-4">
      <h6 className="mb-3">Informations sur la plainte</h6>

      <div className="row">
        <div className="col">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Résumé de la plainte *
          </Form.Label>
          <Form.Control
            onChange={handleResumeChange}
            as="textarea"
            value={plainteData.desc_plt}
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Entrer votre détails..."
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
              padding: "8px"
            }}
          ></Form.Control>

          {errorResume && (
            <span style={{ color: 'red', fontSize: '15px' }}>{errorResume}</span>
          )}
        </div>
        <div className="col">
          <Form.Label htmlFor="exampleFormControlTextarea1">
          Solution attendue *
          </Form.Label>
          { !gestSolution ? 
            <Form.Select
            onChange={handleSolutionAttendueChange}
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
            }}
          >
            <option>Choisir</option>
            {stateSolutions.map((item, key) => {
             
                 return  <option key={key} value={item.id_solution}>
                    {item.lib_solution}
                  </option>
            })}
          </Form.Select>
          :
          <Form.Control
          onChange={handleAutresSolutionChange}
          as="textarea"
          value={plainteData.lib_solution}
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="Entrer les détails de votre solution attendue..."
          style={{
            border: "1px solid #1f365c",
            boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
            borderRadius: "5px",
            padding: "8px"
          }}
        ></Form.Control>
          }
          <Form.Check
            type="checkbox"
            label="Autres Solutions"
            onChange={(e) => chargeAutresSolution(e.target.checked)}
            style={{marginTop : 5}}
          />

          {errorSltAtt && (
            <span style={{ color: 'red', fontSize: '15px' }}>{errorSltAtt}</span>
          )}
        </div>

        <div className="col">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Canal de retour souhaité *
          </Form.Label>
          <Form.Check
            value="0"
            checked={plainteData.canal_retour === "email"}
            onChange={(e) => controleChoixRetour()}
            type="radio"
            label="Email"
          />
          <Form.Check
        
            checked={
              plainteData.canal_retour === "SMS" || 
              plainteData.canal_retour === "Appel" || 
              plainteData.canal_retour === "WhatsApp"  
              }
            onChange={() => handleShow()}
            // onChange={(e) => changePlainteData("canal_retour", "telephone")}
            type="radio"
            label="Téléphone"
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Date du premier constat
          </Form.Label>
          <Form.Control
            value={plainteData.date_constat}
            onChange={(e) => changePlainteData("date_constat", e.target.value)}
            type="date"
            max={moment(today).format('YYYY-MM-DD')}
            id="exampleFormControlInput1"
            placeholder=""
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
              padding: "8px"
            }}
          />
        </div>
        <div className="col-6">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Pièces jointes
          </Form.Label>
          <Form.Control
            onChange={handleFileChange}
            type="file"
            id="exampleFormControlInput1"
            placeholder=""
            name="images[]"
            multiple
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
              padding: "8px"
            }}
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Type de requête</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <Row as="fieldset">
    <Col sm="10">
      <Form.Check type="radio"  label="SMS" 
      checked={
        plainteData.canal_retour === "SMS"  
        } 
      onChange={(e) => changePlainteData("canal_retour", "SMS")}  />

      <Form.Check type="radio"  label="Appel"  
      checked={
      
        plainteData.canal_retour === "Appel"   
        }
      onChange={(e) => changePlainteData("canal_retour", "Appel")}  />

      <Form.Check type="radio"  label="WhatsApp"  
      checked={ 
        plainteData.canal_retour === "WhatsApp"  
        }
      onChange={(e) => changePlainteData("canal_retour", "WhatsApp")}  />
      
     
    </Col>
  </Row>
  </Modal.Body>
  <Modal.Footer>
    {/* <Button variant="secondary" onClick={handleClose}>
      Annuler
    </Button> */}
    <Button variant="success" onClick={handleClose}>
      Valider
    </Button>
  </Modal.Footer>
</Modal>
    </div>
  );
};

export default Step2;
