import { apiSlice } from "../api/apiSlice"

export const typeObligationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTypeObligations: builder.query({
            query: () => ({
                url: '/obligation/type_obligation',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'TypeObligations', id: 'LIST' },
                    ...result.map(item => ({ type: 'TypeObligations', id: item.id_type_ob }))
                ] : ['TypeObligations']
            }
        }),

        createTypeObligation: builder.mutation({
            query: body => ({
                url: '/obligation/type_obligation/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['TypeObligations']
        }),

        updateTypeObligation: builder.mutation({
            query: body => ({
                url: `/obligation/type_obligation/update/${body.id_type_ob}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['TypeObligations']
        }),

        deleteTypeObligation: builder.mutation({
           
            query: (id) => ({
              url: `/obligation/type_obligation/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['TypeObligations'],
          }),
    })
})

export const {
    useGetTypeObligationsQuery,
    useCreateTypeObligationMutation,
    useUpdateTypeObligationMutation,
    useDeleteTypeObligationMutation,
} = typeObligationApiSlice