import { createSlice } from "@reduxjs/toolkit";
import { deleteCookie } from "../../helpers";

const userSlice = createSlice({
  name: "user",
  initialState: {
    info: localStorage.getItem("mon_user")
      ? JSON.parse(localStorage.getItem("mon_user"))
      : {},
  },
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("mon_user", JSON.stringify(action.payload));
      state.info = action.payload;
    },
    logOut: (state, action) => {
      state.info = {};
      localStorage.removeItem("mon_user");
      deleteCookie("userInfo");
    },
  },
});

export const { setUser, logOut } = userSlice.actions;

export const getUserInfo = (state) => state.user.info;

export default userSlice.reducer;
