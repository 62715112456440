
import React from 'react'
import { useGetCompletudeQuery} from '../../../redux/apiSlice/plainteApiSlice'
import Spinner from "react-bootstrap/Spinner";
const Completude = props => {
    const {plainte} = props

   
    const {data: completude = [], isLoading: loadCompl} = useGetCompletudeQuery(plainte.id_plt)

    const soustitreStyle = {
        fontSize: '1.4em', // Taille de la police
        fontWeight: 'bolder', // Gras
        // color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
        // Ajoutez d'autres propriétés de style au besoin
      };

      const commentStyle = {
        fontSize: '1.2em', // Taille de la police
        fontWeight: 'normal', // Gras
        // color: '#000', // Couleur du texte (par exemple, bleu Bootstrap)
        paddingLeft: '35px'
        // Ajoutez d'autres propriétés de style au besoin
      };

    return loadCompl 
    ? 
    ( <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />) 
    :
(<>
            
      {completude.length > 0 && 
                <div className='mt-3 mb-4'>
                    <h4 style={soustitreStyle}>&nbsp;Complétude d'Information</h4>
                    
                   { completude.map((item)=>{
                    return <div  style={commentStyle} dangerouslySetInnerHTML={{ __html: item?.commentaire }} />
                   })
                    }
                    
                </div>
            }
            
        </>)
}

export default Completude
