import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import Sidebar from "../../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableListePlaintes from "../dataTables/TableIntruction";
import TableDetails from "../TableDetails";

export default function Instruction() {
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
 
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Plaintes en attente d'instruction
              </li>
            </ol>
            <h4 className="main-title mb-0">Liste plaintes en attente d'instruction règlement</h4>
          </div>
        </div>

        <div>
          

          <TableListePlaintes 
          handleShow={handleShow}
          handleShow1={handleShow1}
          
          />
         
        </div>
        {/* ----------------Modal de détails----------- */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails Plainte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="mb-2">
                    <h5>Type de plainte</h5>
                    <Form.Label>Achats de Forfaits</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>Opérateur Concerné</h5>
                    <Form.Label>MTN</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>Service concerné</h5>
                    <Form.Label>shop jules</Form.Label>
                  </div>
                  <div className="mb-2">
                    <Form.Check type="radio" label="Personne moral" />
                  </div>
                  <div className="mb-2">
                    <h5>Email</h5>
                    <Form.Label>jules@gmail.com</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>Téléphone</h5>
                    <Form.Label>+229 66778899</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>Adresse géographique</h5>
                    <Form.Label>cotonou</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>
                      Avez-vous fait un recours à l’opérateur au sujet de votre
                      plainte depuis au moins un mois ?
                    </h5>
                    <Form.Check type="radio" label="Oui" />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-2">
                    <h5>
                      Avez-vous obtenu une réponse de la part de l’opérateur ?
                    </h5>
                    <Form.Check type="radio" label="Oui mais pas satisfait(e)" />
                  </div>
                  <div className="mb-2">
                    <h5>
                      Référence de la plainte déposée auprès de l’opérateur
                    </h5>
                    <Form.Label>4RRRR</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>Résumé de la plainte</h5>
                    <Form.Label>La description</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>Date du premier constat</h5>
                    <Form.Label>12/12/2023</Form.Label>
                  </div>
                  <div className="mb-2">
                    <h5>Canal de retour souhaité</h5>
                    <Form.Check type="radio" label="Telephone" />
                  </div>
                </div>
              </div>
            </div>
            {/* <TableDetails mycomplaint={mycomplaint}/> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Accusé de réception
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Plainte classée
            </Button>
          </Modal.Footer>
        </Modal>

    
        <Footer />
      </div>
    </React.Fragment>
  );
}
