import React, { useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.jpg";
import Iso from "../assets/img/iso.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useLoginMutation,
  useVerifyCodeMutation,
} from "../redux/apiSlice/loginApiSlice";
import {
  useCheckMailMutation,
  useCheckCodeMutation,
  useChangePassMutation,
} from "../redux/apiSlice/passWordApiSlice";
import { createCookie } from "../helpers";
import { setUser } from "../redux/slice/userSlice";
import { SET_ID_USER } from "../redux/slice/infoUserSlice";

export default function ForgetPwd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const toaster = useToaster();
  const [checkMail, { isLoading: loadCheck }] = useCheckMailMutation();
  const [checkCode, { isLoading: loadCode }] = useCheckCodeMutation();
  const [changePass, { isLoading: loadPass }] = useChangePassMutation();
  const [verifyCode, { isLoading: loadVerify }] = useVerifyCodeMutation();

  const [step, setStep] = useState("email");
  const [etat, setEtat] = useState(true);

  // const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [code, setCode] = useState("");
  const [idUser, setIdUser] = useState("");

  const verifyEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const save = async () => {
    if (step == "email") {
      const retourMail = verifyEmail(email);

      switch (retourMail) {
        case false:
          toast.error("Veuillez saisir un email valide", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          break;
        default:
          try {
            const user = await checkMail({
              email_user: email,
            }).unwrap();
            setStep("code");
            setEtat(true);
            setIdUser(user?.id_user);
            // console.log("user",user)
            // console.log("idUser",user?.id_user)
          } catch (error) {
            setEtat(false);
            // console.log("error", error)
            if (error.data == "Cet email n'est associé à aucun utilisateur.") {
              toast.error(error.data, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              toast.error("Erreur de connexion , Veuillez réessayer !!!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }

          break;
      }
    } else if (step == "code") {
      try {
        const user = await checkCode({
          id_user: idUser,
          code_forget: code,
        }).unwrap();
        setStep("pwd");
        setEtat(true);
      } catch (error) {
        setEtat(false);
        console.log("error", error);

        toast.error(error.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      if (password !== confirmPass) {
        toast.error("Les mots de passe doivent correspondre", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        try {
          const user = await changePass({
            id_user: idUser,
            pwd_user: password,
          }).unwrap();

          setStep("email");
          setEtat(true);
          toast.success("Changement de mot de passe réussi", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          navigate("/");
        } catch (error) {
          setEtat(false);
          // console.log("error", error);

          toast.error(error.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }
  };

  const checkForm = (lib, value) => {
    switch (lib) {
      case "email":
        setEtat(false);
        setEmail(value);
        break;

      case "code":
        setEtat(false);
        setCode(value);
        break;

      case "pwd":
        setPassword(value);
        const valide = password != "" && confirmPass != "";
        setEtat(!valide);
        break;

      case "rien":
        setConfirmPass(value);
        const valideTwo = password != "" && confirmPass != "";
        setEtat(!valideTwo);

      default:
        break;
    }
  };
  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="d-flex flex-row justify-content-center">
            <Image
              src={Iso}
              alt=""
              style={{ height: "100px", width: "160px" }}
            />
          </Link>
          <Card.Title className="d-flex flex-row justify-content-center mt-4">
            Récupération de passe
          </Card.Title>

          {step == "email" && (
            <>
              <Card.Text>Veuillez saisir votre mail de récupération</Card.Text>
            </>
          )}
          {step == "code" && (
            <>
              <Card.Text>Veuillez saisir le code réçu par mail</Card.Text>
            </>
          )}

          {step == "pwd" && (
            <>
              <Card.Text>Veuillez saisir votre nouveau mo de passe</Card.Text>
            </>
          )}
        </Card.Header>
        <Card.Body>
          {step === "email" && (
            <Form>
              <div className="mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  placeholder="admin34@gmail.com"
                  value={email}
                  onChange={(e) => checkForm("email", e.target.value.trim())}
                />
              </div>
            </Form>
          )}
          {step === "code" && (
            <Form>
              <div className="mb-4">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="5"
                  pattern="[0-9]"
                  value={code}
                  onChange={(e) => {
                    checkForm("code", e.target.value.trim());
                  }}
                />
              </div>
            </Form>
          )}
          {step === "pwd" && (
            <Form>
              <div className="mb-4">
                <Form.Label className="d-flex justify-content-between">
                  Password{" "}
                </Form.Label>
                <Form.Control
                  // type={visible ? "text" : "password"}
                  type="password"
                  placeholder="**********"
                  value={password}
                  onChange={(e) => checkForm("pwd", e.target.value.trim())}
                />
              </div>
              <div className="mb-4">
                <Form.Label className="d-flex justify-content-between">
                  Confirmation{" "}
                </Form.Label>
                <Form.Control
                  // type={visible ? "text" : "password"}
                  type="password"
                  placeholder="**********"
                  value={confirmPass}
                  onChange={(e) => checkForm("rien", e.target.value.trim())}
                />
              </div>
            </Form>
          )}

          <Button
            variant="primary"
            className="btn-sign"
            disabled={etat}
            onClick={() => save()}
          >
            {loadCheck ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Se connecter</span>
            )}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
