export const checkEmail = (email) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email)
}

export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export const stringToColor = text => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
  return `#${'00000'.substring(0, 6 - color.length)}${color}`;
}

export const shortText = text => text.length > 50 ? text.substring(0, 50) + "..." : text 

export const numberFormat = number => number < 10? `0${number}` : number;

export const getDomainName = () => {
    const d = window.document.location.hostname.split('.')[0]
    return d === 'localhost' ? 'localhost' : 'swagger.letesarl.com'
}

export const deleteCookie = name => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const getCookie = name => {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      try {
        return JSON.parse(cookieValue);
      } catch (error) {
        return cookieValue;
      }
    }
  }
  return null;
}

export const createCookie = (name, data) => {
    const expirationDate = new Date();
    //expirationDate.setDate(expirationDate.getDate() + 7);
    expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000)
    document.cookie = `${name}=${JSON.stringify(data)}; domain=${getDomainName()}; path=/; expires=${expirationDate.toUTCString()}`;
}