import { apiSlice } from "../api/apiSlice"

export const pwdApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
       

       checkMail: builder.mutation({
            query: body => ({
                url: '/login/forgot/checkmail',
                method: 'POST',
                body
            }),
        }),

        checkCode: builder.mutation({
            query: body => ({
                url: '/login/forgot/verify_2fa',
                method: 'POST',
                body
            }),
        }),
 
       changePass: builder.mutation({
            query: body => ({
                url: '/login/forgot/change',
                method: 'POST',
                body
            }),
        }),
 
    })
})

export const {
    useCheckMailMutation,
    useCheckCodeMutation,
    useChangePassMutation,
    
} = pwdApiSlice