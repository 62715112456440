import React, { Children } from "react";

const DashCards = ({ icon, titreCard, nbreElt, bordureBas,Children }) => {
  return (
    <div className="container_general d-flex flex-column justify-content-center">
      <div className={`container_contenu ${bordureBas}`}>
        {icon}{" "}
        <div className="texte_nbre_titre">
          <span className="titre_card">{titreCard}</span>
          <span className="nombre_elt">{nbreElt}</span>
        </div>
      </div>
    </div>
  );
};

export default DashCards;
