import { apiSlice } from "../api/apiSlice";

export const catFaqApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCatFaq: builder.query({
      query: () => ({
        url: "/categorie_rens",
        method: "GET",
      }),
    }),
    createCatFaq: builder.mutation({
      query: (body) => ({
        url: "/categorie_rens/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CategorieFaq"],
    }),
    updateCatFaq: builder.mutation({
      query: (body) => ({
        url: `/categorie_rens/update/${body.id_cat_rens }`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["CategorieFaq"],
    }),
    deleteCatFaq: builder.mutation({
      query: (body) => ({
        url: `/categorie_rens/del/${body.id_cat_rens}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["CategorieFaq"],
    }),
  }),
});

export const {
  useGetCatFaqQuery,
  useCreateCatFaqMutation,
  useUpdateCatFaqMutation,
  useDeleteCatFaqMutation,
} = catFaqApiSlice;
