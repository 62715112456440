import { apiSlice } from "../api/apiSlice"

export const paramEmailApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getParamEmail: builder.query({
            query: () => ({
                url: '/settings/email',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'ParamEmail', id: 'LIST' },
                    ...result.map(item => ({ type: 'ParamEmail', id: item.id }))
                ] : ['ParamEmail']
            }
        }),

        // createParamEmail: builder.mutation({
        //     query: body => ({
        //         url: '/obligation/element_ctrl/create',
        //         method: 'POST',
        //         body
        //     }),
        //     invalidatesTags: ['ParamEmail']
        // }),

        updateParamEmail: builder.mutation({
            query: body => ({
                url: `/settings/email/update`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['ParamEmail']
        }),

       
        // deleteParamEmail: builder.mutation({
           
        //     query: (id) => ({
        //       url: `/obligation/element_ctrl/del/${id}`,
        //       method: 'DELETE',
        //     }),
        //     invalidatesTags: ['ParamEmail'],
        //   }),
       
    })
})

export const {
    useGetParamEmailQuery,
    // useCreateParamEmailMutation,
    useUpdateParamEmailMutation,
    // useDeleteParamEmailMutation,

} = paramEmailApiSlice