import React, { useEffect, useState } from "react";
import { Form, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";

import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";

import { getUserInfo } from "../../../redux/slice/userSlice.js";
import {
  useGetSousTrancheQuery, //liste sous tranche
  useGetTrancheQuery, //liste des tranche
  useGetRessourceListeTrancheQuery, //recuperation de ressources par tranche
  useGetRessourceListeSousTrancheQuery, //recuperation de ressources par sous tranche
  useGetClientsQuery,
  useGetOccupantsQuery,
  useGetRessourcesSousTrancheParTrancheQuery,
} from "../../../redux/apiSlice/numerotationApiSlice.js";
import { useGetTypeOperateurQuery } from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";

import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";
import TableSansAb from "./dataTables/TableSansAb.js";
import { useCreateLogPageMutation } from "../../../redux/apiSlice/logPageApiSlice.js";

export default function ListePlainte() {
  const { data: tranches = [] } = useGetTrancheQuery();
  const { data: sousTranches = [] } = useGetSousTrancheQuery();
  const { data: lesOps } = useGetOperateursQuery();
  const { data: typeOp } = useGetTypeOperateurQuery();
  const { data: lesClients } = useGetClientsQuery();
  const { data: lesOccupants = [] } = useGetOccupantsQuery();
  // ****
  const [dataTrancheSansAb, setDataTrancheSansAb] = useState([]);
  const [id_tranche, setId_tranche] = useState("");
  const [id_sous_tranche, setId_sous_tranche] = useState("");

  const [id_type_op, setId_type_op] = useState("");
  const [elt_filtre, setElt_filtre] = useState("");

  const [tableSousTranche, setTableSousTranche] = useState([]);
  const [selectedOptionSousTranche, setSelectedOptionSousTranche] = useState(
    []
  );
  const [tableOp, setTableOp] = useState([]);
  const [selectedOptionOp, setSelectedOptionOp] = useState([]);
  const [tableStatuts, setTableStatuts] = useState([]);
  const [selectedOptionStatuts, setSelectedOptionStatuts] = useState([]);
  const [ressourceSousTranche, setRessourceSousTranche] = useState([]);

  const { data: listeTranche = [] } =
    useGetRessourceListeTrancheQuery(id_tranche);
  var new_sous_tranche = sousTranches.filter(
    (elt) => elt.id_tranche == id_tranche
  );

  const { data: listeSousTranche = [] } =
    useGetRessourceListeSousTrancheQuery(id_sous_tranche);//ROUTE A REVOIR

  const { data: allRessourcesSousTrancheParTranche = [] } =
    useGetRessourcesSousTrancheParTrancheQuery(id_tranche);

  const lesStatuts = [
    {
      value: 1,
      label: "Libre",
    },
    {
      value: 2,
      label: "En instance",
    },
    {
      value: 3,
      label: "Réservée",
    },
    {
      value: 4,
      label: "Attribuée",
    },
    {
      value: 5,
      label: "Bloquée",
    },
    {
      value: 6,
      label: "Inutilisable",
    },
  ];

  const getRessourcesParTranche = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("https://apisymfonypigru.leavesmartconsulting.com/pigru/numerotation/get_all/sous_tranche",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            id_sous_tranche,
            
          },
        })

        .then((res) => {
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  };

  useEffect(() => {
    getRessourcesParTranche()
      .then((r) => {
        setRessourceSousTranche(r?.data);
      })
      .catch((e) => console.error(e));
  }, [id_sous_tranche, selectedOptionSousTranche]);

  useEffect(() => {
    if (new_sous_tranche.length > 0) {
      const sortedList = new_sous_tranche
        ?.slice()
        ?.sort((a, b) => a?.lib_s_tran - b?.lib_s_tran)
        ?.map((soustranch) => ({
          value: soustranch.id_s_tran,
          label: soustranch.lib_s_tran,
        }));
      setTableSousTranche(sortedList);
    } else {
      setTableSousTranche([]);
    }
  }, [id_tranche, sousTranches]);

  useEffect(() => {
    const selectedIdsSousTranche = selectedOptionSousTranche.map(
      (option) => option.value
    );
    setId_sous_tranche(selectedIdsSousTranche);
    if (selectedIdsSousTranche.length > 0) {
      handleChange("sous_tranche", selectedIdsSousTranche);
    }
  }, [selectedOptionSousTranche]);

  useEffect(() => {
    setTableOp(() =>
      lesOps
        ?.filter((op) => op.id_type_op === parseInt(id_type_op))
        .map((op) => ({
          value: op.id_operateur,
          label: op.nom_operateur,
        }))
    );
  }, [id_type_op, lesOps]);

  useEffect(() => {
    const selectedIdsOp = selectedOptionOp.map((option) => option.value);
    if (selectedIdsOp.length > 0) {
      handleChange("id_operateur", selectedIdsOp);
    }
  }, [selectedOptionOp]);

  useEffect(() => {
    setTableStatuts(() =>
      lesStatuts?.map((statut) => ({
        value: statut.value,
        label: statut.label,
      }))
    );
  }, []);

  useEffect(() => {
    const selectedIdsStatuts = selectedOptionStatuts.map(
      (option) => option.value
    );
    
    if (selectedIdsStatuts.length > 0) {
      handleChange("id_status", selectedIdsStatuts);
    }
  }, [selectedOptionStatuts]);

  // ---****---
  const stat = (value) => {
    switch (value) {
      case 1:
        return "Libre";
      case 2:
        return "En instance";
      case 3:
        return "Réservée";
      case 4:
        return "Attribuée";
      case 5:
        return "Bloquée";
      case 6:
        return "Inutilisable";
      default:
        break;
    }
  };

  const handleChange = (lib, value) => {
    const table_id1 = tranches
      .filter((tranch) => [1].includes(parseInt(tranch.lib_tranche)))
      .map((tranch) => tranch.id_tranche);

    const table_id2 = tranches
      .filter((tranch) => [3, 7, 8].includes(parseInt(tranch.lib_tranche)))
      .map((tranch) => tranch.id_tranche);

    const newValue = Array.isArray(value) ? value.map(Number) : value;

    switch (lib) {
      case "tranche":
        setId_tranche(newValue);
        break;

      case "sous_tranche":
        setId_sous_tranche(newValue);
        break;

      case "id_status":
        handleStatusChange(newValue, table_id1, table_id2);
        break;

      case "id_type_op":
        handleEltFiltreChange(newValue, table_id1, table_id2);
        break;

      case "id_operateur":
        handleOperateurChange(newValue, table_id1);
        break;

      case "id_clt":
        handleCltChange(newValue, table_id1, table_id2);
        break;

      default:
        break;
    }
  };
  // console.log("liste tranche", listeTranche);
  // console.log("liste sous tranche", listeSousTranche);
  // console.log("liste all ress", allRessourcesSousTrancheParTranche);
  // console.log("les occup", lesOccupants);
  const handleStatusChange = (value, table_id1, table_id2) => {
    if (table_id2.includes(id_tranche)) {
      setDataTrancheSansAb(
        filterData(
          listeTranche,
          (tranch) => value.includes(tranch?.id_stat_num),
          table_id1
        )
      );
    } else if (table_id1.includes(id_tranche) && id_sous_tranche === "") {
      setDataTrancheSansAb(
        filterData(
          allRessourcesSousTrancheParTranche,
          (tranch) => value.includes(tranch?.id_stat_num),
          table_id1
        )
      );
    } else {
      setDataTrancheSansAb(
        filterData(
          ressourceSousTranche,
          (tranch) => value.includes(tranch?.id_stat_num),
          table_id1
        )
      );
    }
  };

  const handleEltFiltreChange = (value, table_id1, table_id2) => {
    const filterByTypeOccupant = (data, trancheKey, trancheId, type_op_id) =>
      data.filter((tr) =>
        lesOccupants.some(
          (occ) =>
            occ?.Operateur?.id_type_op === type_op_id &&
            occ.id_comp === tr.id_comp &&
            tr[trancheKey].id_tranche === trancheId &&
            tr.id_stat_num === 4
        )
      );

    let filteredData = [];
    if (table_id2.includes(id_tranche)) {
      filteredData = filterByTypeOccupant(
        listeTranche,
        "tranche",
        id_tranche,
        value
      );
    } else if (table_id1.includes(id_tranche)) {
      if (id_sous_tranche === "") {
        filteredData = filterByTypeOccupant(
          allRessourcesSousTrancheParTranche,
          "S_tranche",
          id_tranche,
          value
        );
      } else {
        filteredData = filterByTypeOccupant(
          ressourceSousTranche,
          "tranche",
          id_tranche,
          value
        );
      }
    }

    setDataTrancheSansAb(
      filteredData.map((j) => ({
        id_comp: j.id_comp,
        lib_tranche: table_id1.includes(id_tranche)
          ? 1
          : j.tranche?.lib_tranche,
        lib_comp: j.lib_comp,
        id_stat_num: stat(j.id_stat_num),
      }))
    );
    setId_type_op(value);
  };

  const handleOperateurChange = (value, table_id1) => {
    const filterByOccupant = (data, occupant) =>
      data.filter((tr) =>
        lesOccupants.some(
          (occ) =>
            occ?.Operateur?.id_type_op === id_type_op &&
            occupant.includes(occ.id_occupant) &&
            occ.id_comp === tr.id_comp &&
            tr?.id_stat_num === 4
        )
      );
    let filteredData = [];
    if (table_id1.includes(id_tranche)) {
      if (id_sous_tranche === "") {
        filteredData = filterByOccupant(
          allRessourcesSousTrancheParTranche,
          value
        );
      } else {
        filteredData = filterByOccupant(ressourceSousTranche, value);
      }
    } else {
      filteredData = filterByOccupant(listeTranche, value);
    }
    setDataTrancheSansAb(
      filteredData.map((j) => ({
        id_comp: j?.id_comp,
        lib_tranche: table_id1.includes(id_tranche)
          ? 1
          : j.tranche?.lib_tranche,
        lib_comp: j?.lib_comp,
        id_stat_num: stat(j?.id_stat_num),
      }))
    );
  };

  const handleCltChange = (value, table_id1) => {
    const filterByOccupant = (data, occupant) =>
      data.filter((tr) =>
        lesOccupants.some(
          (occ) =>
            occ.type_occupant === elt_filtre &&
            occupant.includes(occ.id_occupant) &&
            occ.id_comp === tr.id_comp &&
            tr?.id_stat_num === 4
        )
      );
    let filteredData = [];

    if (table_id1.includes(id_tranche)) {
      if (id_sous_tranche === "") {
        filteredData = filterByOccupant(
          allRessourcesSousTrancheParTranche,
          value
        );
      } else {
        filteredData = filterByOccupant(ressourceSousTranche, value);
      }
    } else {
      filteredData = filterByOccupant(listeTranche, value);
    }

    setDataTrancheSansAb(
      filteredData.map((j) => ({
        id_comp: j?.id_comp,
        lib_tranche: table_id1.includes(id_tranche)
          ? 1
          : j.tranche?.lib_tranche,
        lib_comp: j?.lib_comp,
        id_stat_num: stat(j?.id_stat_num),
      }))
    );
  };

  const filterData = (data, predicate, table) => {
    return data.filter(predicate).map((j) => ({
      id_comp: j?.id_comp,
      lib_tranche: table.includes(id_tranche) ? 1 : j.tranche?.lib_tranche,
      lib_comp: j?.lib_comp,
      id_stat_num: stat(j?.id_stat_num),
    }));
  };

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({
          content: "Statistique tranche sans ab",
        }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);

  useEffect(() => {
    const table_id_sous_tranche1 = tranches
      .filter((tranch) => [1].includes(parseInt(tranch.lib_tranche)))
      .map((tranch) => tranch.id_tranche);
    if (table_id_sous_tranche1.includes(id_tranche))
      setDataTrancheSansAb(() => {
        return allRessourcesSousTrancheParTranche.map((j) => ({
          id_comp: j?.id_comp,
          lib_tranche: 1,
          lib_comp: j?.lib_comp,
          id_stat_num: stat(j?.id_stat_num),
        }));
      });
    else
      setDataTrancheSansAb(() => {
        return listeTranche.map((j) => ({
          id_comp: j?.id_comp,
          lib_tranche: j?.tranche?.lib_tranche,
          lib_comp: j?.lib_comp,
          id_stat_num: stat(j?.id_stat_num),
        }));
      });
  }, [
    listeTranche.length,
    allRessourcesSousTrancheParTranche.length,
    id_tranche,
  ]);

  useEffect(() => {
    setDataTrancheSansAb(() => {
      return ressourceSousTranche.map((j) => ({
        id_comp: j?.id_comp,
        lib_tranche: j?.tranche?.lib_tranche,
        lib_comp: j?.lib_comp,
        id_stat_num: stat(j?.id_stat_num),
      }));
    });
  }, [ressourceSousTranche.length, id_sous_tranche]);

  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
  }, []);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Numerotation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistique ressource</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col sm="12" xl="4">
            <Card className="card-one" style={{ height: "120px" }}>
              <Card.Body className="overflow-hidden px-0">
                <div className="finance-info p-3 p-xl-4 pb-4">
                  <Row>
                    <Col
                      sm="12"
                      xl="12"
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      <label className="fs-sm fw-medium mb-2 mb-xl-1">
                        Nombre de ressources
                      </label>

                      <h4 className="finance-value">
                        {dataTrancheSansAb?.length}
                      </h4>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12" sm="12" className="d-flex flex-column gap-2">
            <Row className="g-2">
              <Col sm="12" xl="4" className="d-flex flex-column">
                <Form.Label htmlFor="tranche">Tranche:</Form.Label>

                <Form.Select
                  id="tranche"
                  onChange={(e) =>
                    handleChange("tranche", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {tranches
                    .filter((tranch) =>
                      [1, 3, 7, 8].includes(parseInt(tranch.lib_tranche))
                    )
                    .map((tranch, key) => (
                      <option key={key} value={tranch.id_tranche}>
                        {tranch.lib_tranche}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              <Col sm="12" xl="4" className="d-flex flex-column">
                <Form.Label htmlFor="soustranche">Sous tranche:</Form.Label>
                <Select
                  options={tableSousTranche}
                  isSearchable={true}
                  isMulti
                  onChange={setSelectedOptionSousTranche}
                />
                {/* <Form.Select
                  id="soustranche"
                  onChange={(e) =>
                    handleChange("sous_tranche", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {new_sous_tranche?.map((soustranch, key) => (
                    <option key={key} value={soustranch.id_s_tran}>
                      {soustranch.lib_s_tran}
                    </option>
                  ))}
                </Form.Select> */}
              </Col>
              <Col sm="12" xl="4">
                <Form.Label htmlFor="statut">Statuts :</Form.Label>
                <Select
                  options={tableStatuts}
                  isSearchable={true}
                  isMulti
                  onChange={setSelectedOptionStatuts}
                />
                {/* <Form.Select
                  id="statut"
                  // value={lesChamp.id_status}
                  onChange={(e) =>
                    handleChange("id_status", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  <option value="3">Réservée</option>
                  <option value="1">Libre</option>
                  <option value="2">En instance</option>
                  <option value="4">Attribuée</option>
                  <option value="6">Inutilisable</option>
                  <option value="5">Bloquée</option>
                </Form.Select> */}
              </Col>
              <Col sm="12" xl="6">
                <Form.Label htmlFor="elt_filtre">Type d'opérateur :</Form.Label>

                <Form.Select
                  id="typ_op"
                  // value={lesChamp.id_type_op}
                  onChange={(e) =>
                    handleChange("id_type_op", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {typeOp?.map((typOp, key) => (
                    <option key={key} value={typOp.id_type_op}>
                      {typOp.lib_type_op}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col sm="12" xl="6">
                <Form.Label htmlFor="op">Opérateur :</Form.Label>
                <Select
                  options={tableOp}
                  isSearchable={true}
                  isMulti
                  onChange={setSelectedOptionOp}
                />
                {/* <Form.Select
                  id="op"
                  // value={lesChamp.id_operateur}
                  onChange={(e) =>
                    handleChange("id_operateur", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>

                  {lesOps?.map((op, key) => {
                    if (op.id_type_op === parseInt(id_type_op)) {
                      return (
                        <option key={key} value={op.id_operateur}>
                          {op.nom_operateur}
                        </option>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Form.Select> */}
              </Col>
            </Row>
          </Col>
          <Col xl="12" sm="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des ressources
                </p>
                <TableSansAb ab={dataTrancheSansAb} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
