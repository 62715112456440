import { apiSlice } from "../api/apiSlice";

export const logPageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogPage: builder.query({
      query: (id) => ({
        url: `/log/get_log_page/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["logPage"],
    }),
    getLogUserPage: builder.query({
      query: (body) => ({
        url: `/log/get_log_open_page`,
        method: "GET",
      }),
      invalidatesTags: ["logPage"],
    }),
    createLogPage: builder.mutation({
      query: (body) => ({
        url: "/log/open_page_log/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["logPage"],
    }),
    getLogEntre2Periodes: builder.query({
      query: (body) => ({
        url: `log/get_log_open_page/${body.debut}/${body.fin}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetLogPageQuery,
  useGetLogEntre2PeriodesQuery,
  useGetLogUserPageQuery,
  useCreateLogPageMutation,

} = logPageApiSlice;
