import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Image } from "react-bootstrap";
import LogoArcep from "../assets/img/ARCEP-LOGOTYPE.jpg";
import {useGetProfilByIdQuery } from "../redux/apiSlice/profileApiSlice";
import Spinner from "react-bootstrap/Spinner";


export default function Sidebar() {

  const [id_profil, setId_profil] = useState(0);
  const [acces, setAcces] = useState([]);
  const { data: profilesById = [], isLoading: loadProfileById } = useGetProfilByIdQuery(id_profil);
  const icons = [
    {
      id: 1,
      icon: "ri-pie-chart-2-line",
      etat: false,
      fieldkey: true,
    },
    {
      id: 2,
      icon: "ri-calendar-todo-line",
      etat: false,
      fieldkey: true,
    },
    {
      id: 3,
      icon: "ri-mail-send-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 4,
      icon: "ri-shopping-bag-3-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 5,
      icon: "ri-shopping-bag-3-line",
      etat: true,
      fieldkey: false,
    },
    {
      id: 6,
      icon: "ri-checkbox-multiple-line",
      etat: true,
      fieldkey: false,
    },
    {
      id: 7,
      icon: "ri-checkbox-multiple-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 8,
      icon: "ri-shopping-bag-3-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 9,
      icon: "ri-hard-drive-2-line",
      etat: false,
      fieldkey: false,
    },
  ];

  
  useEffect(() => {
   
    
    const storedData = localStorage.getItem("mon_user");
    if (storedData) {
      let info = JSON.parse(storedData);
      setId_profil(parseInt(info?.id_profil))
    } else {
      console.log("Aucune donnée utilisateur trouvée dans le localStorage");
    }

  
    if (profilesById) {
    const tab =  profilesById.map((item)=>{
        const objetTrv = icons.find((icon)=>icon.id == item.id)
        if (objetTrv) {
          return {...item, icon : objetTrv.icon}
        }
      })
      setAcces(tab)

    }
  }, [profilesById]);

  return (
    <div className="sidebar">
      <div className="sidebar-header d-flex flex-row justify-content-center">
        <Image src={LogoArcep} alt="" width={120} height={50} style={{borderRadius:"5px"}}/>
      </div>
      <PerfectScrollbar className="sidebar-body">
       { 
    
       <SidebarMenu
          menu={acces}
        />}
      </PerfectScrollbar>
    </div>
  );
}

class SidebarMenu extends Component {


toggleSubMenu = (e) => {

  let parent = e.target.closest('.nav-item');

  parent.classList.toggle('show');

  let childElement = document.querySelector('.active');

  let parentElement = childElement.closest('.nav-item');
  parentElement.classList.add('show');

  
}


  toggleMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest('.nav-group');
    parent.classList.toggle('show');
    this.props.onUpdateSize();
}


  populateMenu = (data) => {
    const m = data.slice().sort((a, b) => a.id - b.id);

    const menu = m.map((m, key) => {
      let sm;
      const circleBadgeStyle = {
        display: "inline-block",
        width: "20px",
        height: "15px",
        lineHeight: "15px",
        borderRadius: "15px",
        fontSize: "10px",
        textAlign: "center",
        backgroundColor: "#4f84c4",
        color: "#fff",
      };
      if (m.fonc) {
        sm = m.fonc.map((sm, key) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "98%",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "95%",
                }}
              >
                <NavLink to={sm.link} className="nav-sub-link" key={key}>
                  {sm.libFonc}
                </NavLink>
              </div>
              {sm.nbr > 0 && (
                <span style={circleBadgeStyle}>
                  {sm.nbr}
                </span>
              )}
              {parseInt(sm.nbr) === 0 && (
                <span style={circleBadgeStyle}>
                  {sm.nbr}
                </span>
              )}
            </div>
          );
        });
      }
      return (
        <li key={key} className={!sm ? "nav-item" : "nav-item show"}>
          {!sm ? (
            <NavLink to={m.link} className="nav-link">
              <i className={m.icon}></i> <span>{m.label} </span>
            </NavLink>
          ) : (
            <div 

            onClick={this.toggleSubMenu} 

            className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label} </span>
            </div>
          )}
          {m.fonc && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };


  render() {
    const { menu } = this.props;
    return (
      <React.Fragment>
        <div className="nav-group show">
          {
            menu.length > 0 ?
          
          this.populateMenu(menu)
        
        :
        <div className="d-flex justify-content-center">
  <Spinner animation="border" />
</div>
        }

        
        </div>
        
      </React.Fragment>
    );
  }
}


