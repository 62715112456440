import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Spinner from "react-bootstrap/Spinner";
import { Button } from 'primereact/button';
import { Button as Btn, Form, Table, Modal, Badge } from "react-bootstrap";
import '../styleObligation.css'
import { useUpdateDateSuiviMutation } from '../../../redux/slice/oblgOperateurApiSlice';
import { useArchiverSuiviMutation, useCreatPieceSuiviMutation } from '../../../redux/slice/oblgOperateurApiSlice';
import Swal from 'sweetalert2';
import { Tooltip as Tol, OverlayTrigger } from "react-bootstrap";
import { format } from 'date-fns';
import 'jspdf-autotable';
import { Stack } from 'rsuite'

export default function TableSuivi(props) {

  const { listeTab, operateur, handleShow1, typeOperateur, setListeTab } = props
  // console.log("listeTab", listeTab)

  const [globalFilter, setGlobalFilter] = useState([]);
  const [interOpFilter, setInterOpFilter] = useState([]);
  const [affSuivi, setAffSuivi] = useState({});
  const [idOperateur, setIdOperateur] = useState();
  const [pageOperateur, setPageOperateur] = useState([]);
  const [pageSuivi, setPageSuivi] = useState([]);
  const [load, setLoad] = useState(false);
  const [global, setGlobal] = useState([]);

  const [pieces, setPieces] = useState({ images: [] });

  const [archiverSuivi, { isLoading: loadUpdate }] = useArchiverSuiviMutation()
  const [creatPieceSuivi, { isLoading: createUpdate }] = useCreatPieceSuiviMutation()
  const [updateDateSuivi, { isLoading: loadup }] = useUpdateDateSuiviMutation()

  // modal 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false)

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false)

  const [show3, setShow3] = useState(false);
  const [id3, setId3] = useState();

  const [show4, setShow4] = useState(false);
  const [liste_pieces, setListe_pieces] = useState([]);

  const handleClose3 = () => setShow3(false)

  const handleClose4 = () => setShow4(false)

  const handleShow = (rowData) => {
    // console.log("rowData", rowData)
    setAffSuivi(rowData)
    setShow(true)
  };

  const handleShow2 = (rowData) => {
    // console.log("rowData", rowData):
    setAffSuivi(rowData)
    setShow2(true)
  };
  const handleShow3 = (rowData) => {
    // console.log("rowData", rowData):
    setId3(rowData)
    setShow3(true)
  };

  const handleShow4 = (rowData) => {
    // console.log("rowData", rowData):
    setListe_pieces(rowData)
    setShow4(true)
  };

  function formaterDate(dateStr) {
    const date = new Date(dateStr);
    return format(date, "dd-MM-yyyy");
  }

  const chargeTypeCat = (id) => {

    if (id != "") {
      const obligationChoisi = listeTab.filter((item) => (item.id_type_op == id))

      setPageSuivi(obligationChoisi)
      setInterOpFilter(obligationChoisi)

      const operateurChoisi = operateur.filter((item) => (item.id_type_op == id))

      setPageOperateur(operateurChoisi)
    } else {
      setPageSuivi(global)
      setPageOperateur([])
    }


  }

  const checkOperateur = (idOp) => {

    const obligationChoisi = interOpFilter.filter((item) => (item.id_operateur == idOp))

    setPageSuivi(obligationChoisi)
    // setInterOpFilter(obligationChoisi)


  }


  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    return format(dateObject, 'yyyy-MM-dd');
  };

  const handleEditDate = async (date) => {
    setAffSuivi({ ...affSuivi, date_vigueur: date })


  };

  const updateDate = async () => {
    handleClose2()
    try {

      const confirmResult = await Swal.fire({
        title: "Êtes-vous sûr?",
        text: "De vouloir modifier!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Non",
        confirmButtonText: "Oui, supprimer!"
      });

      if (confirmResult.isConfirmed) {


        await updateDateSuivi({ id_ob_op: affSuivi?.id_ob_op, date_vigueur: affSuivi?.date_vigueur }).unwrap();

        const result = await Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success"
        });
        if (result) {
          window.location.href = "/suivi-obligation-reglmt";
        }
        setLoad(!load)
      }
    } catch (error) {

      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: error.data.message,

      });
    }
  }

  const archirver = async (id) => {

    try {

      const confirmResult = await Swal.fire({
        title: "Êtes-vous sûr de vouloir archiver ?",
        // text: "Vous ne pourrez pas annuler cette action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, archicher!",
        cancelButtonText: "Non",

      });

      if (confirmResult.isConfirmed) {

        await archiverSuivi({ id_ob_op: id }).unwrap()

        Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success"
        });

      }
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);

      // Affichez une notification d'erreur
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur s'est produite lors de l'archivage.",
      });
    } finally {
      // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)
    }
  };
  const changePieces = (field, value) => setPieces({ ...pieces, [field]: value });

  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;

    changePieces("images", selectedFiles);
  };


  const sendPieces = async () => {


    if (pieces.images.length > 0) {
      handleClose3()
      try {
        const formData = new FormData();
        for (let i = 0; i < pieces.images.length; i++) {
          formData.append("images[]", pieces.images[i]);
        }
        formData.append("id_ob_op", id3)

        await creatPieceSuivi(formData).unwrap();

        Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success"
        });

      } catch (error) {

        console.log("error", error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur s'est produite lors de l'ajout des fichiers .",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Veuillez choisir au moisn un fichier.",
      });
    }


  }

  useEffect(() => {
    setPageSuivi(listeTab)
    setGlobal(listeTab)
  }, [listeTab, load]);


  const dt = useRef(null);

  const cols = [
    // { field: 'id_ob_op', header: ' N° de suivi', sortable: true },

    { field: 'lib_obligation_reg', header: 'Obligation', sortable: true },
    { field: 'operateur', header: 'Operateur', sortable: true },
    { field: 'datd', header: "Date d'autorisation", sortable: true },
    { field: 'jrsRst', header: 'jour(s) restant(s)', sortable: true },
    { field: 'datf', header: "Echéance alerte", sortable: true },
    { field: 'status', header: 'Statuts', sortable: true },
    { field: 'actions', header: 'Actions', sortable: false }
  ];
  const colsExport = [
    // { field: 'id_ob_op', header: ' N° de suivi', sortable: true },

    { field: "num", header: "N°", sortable: true },
    { field: 'lib_obligation_reg', header: 'Obligation', sortable: true },
    { field: 'operateur', header: 'Operateur', sortable: true },
    { field: 'dat_echeance', header: "Date d'autorisation", sortable: true },
    { field: 'jrsRst', header: 'jour(s) restant(s)', sortable: true },
    { field: 'date_vigueur', header: "Echéance alerte", sortable: true },
    { field: 'stExp', header: 'Statuts', sortable: true },
  ];

  const exportColumns = colsExport.map((col) => ({ title: col.header, dataKey: col.field }));



  useEffect(() => {



  }, []);

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        // const doc = new jsPDF.default();
        const doc = new jsPDF.default({ orientation: "landscape" }); // Spécifie le mode paysage

        const title = "Tableau de suivi des obligations règlementaire"; // Titre de votre document
        const tableStartY = 20; // Position de départ du tableau
        let isFirstPage = true; // Indicateur pour la première page

        // Configuration du tableau
        const tableConfig = {
          startY: tableStartY, // Position de départ du tableau
          theme: "grid", // Thème du tableau
          styles: {
            overflow: "linebreak", // Gérer le renvoi à la ligne automatique
          },
          columnStyles: {
            // Styles des colonnes
            // Ajoutez les styles pour chaque colonne si nécessaire
          },
          didDrawPage: (data) => {
            // Afficher le titre uniquement sur la première page
            if (isFirstPage) {
              doc.text(title, 14, 15);
              isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
            }

            // Gérer la position de départ du tableau pour le centrage
            const tableWidth = data.table.width;
            const pageWidth = doc.internal.pageSize.width;
            const x = (pageWidth - tableWidth) / 2;
            const y = tableStartY - 10;
            data.cursor.y = y;
            data.cursor.x = x;
          },
        };

        // Entêtes du tableau
        const header = exportColumns.map((col) => col.title);

        // Données du tableau
        const data = pageSuivi.map((row, rowIndex) =>
          exportColumns.map((col) => {
            if (col.dataKey == "num") {
              return rowIndex + 1; // Auto-incrémenter le numéro de ligne
            } else {
              return row[col.dataKey]; // Accéder aux données avec le champ de la colonne
            }
          })
        );

        // Génération du tableau dans le PDF
        doc.autoTable(header, data, tableConfig);

        // Enregistrement du PDF
        doc.save("suivi_obligation.pdf");
      });
    });
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      // Créer les entêtes à partir de colsExport
      const headers = colsExport.map(col => col.header);
      const fields = colsExport.map(col => col.field);

      // Créer les données formatées
      const data = pageSuivi.map((item, index) => {
        let rowData = {};
        fields.forEach((field, i) => {
          if (field === 'num') {
            rowData[headers[i]] = index + 1; // Numéro auto-incrémenté
          } else {
            rowData[headers[i]] = item[field];
          }
        });
        return rowData;
      });

      // Ajouter les entêtes comme première ligne des données
      const worksheetData = [headers, ...data.map(Object.values)];

      // Créer la feuille de calcul à partir des données formatées
      const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

      // Générer le buffer Excel
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Sauvegarder le fichier Excel
      saveAsExcelFile(excelBuffer, 'suivi_obligation');
    });
  };


  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">

        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
        </div>
      </div>
      <div className='container_export'>
        <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{ borderRadius: 30 }} severity="danger" size="small" />
        <Button type="button" icon="pi pi-file-excel" className="" severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{ borderRadius: 30 }} size="small" />
        <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{ borderRadius: 30 }} size="small" severity="secondary" />
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {

    return (
      <div className="flex align-items-center">
        <OverlayTrigger

          placement={"top"}
          overlay={
            <Tol>
              Détails
            </Tol>
          }
        >
          <a onClick={() => handleShow(rowData)} className="badge bg-success p-2 mr-1" style={{ borderRadius: 30, cursor: 'pointer' }}>
            <i className="ri-eye-line ri-2x"></i>
            <span></span>
          </a>
        </OverlayTrigger>
        <OverlayTrigger

          placement={"top"}
          overlay={
            <Tol>
              Modifier
            </Tol>
          }
        >
          <a onClick={() => handleShow2(rowData)} className="badge bg-success p-2 mr-1" style={{ borderRadius: 30, cursor: 'pointer' }}>
            <i class="ri-pencil-line  ri-2x"></i>
            <span></span>
          </a>
        </OverlayTrigger>
        {
          rowData.jrsRst < 0 &&
          <OverlayTrigger

            placement={"top"}
            overlay={
              <Tol>
                Archiver
              </Tol>
            }
          >
            <a onClick={() => archirver(rowData.id_ob_op)} class="badge bg-danger p-2 ml-2" style={{ borderRadius: 30, cursor: "pointer" }}><i class="ri-archive-line  ri-2x"></i><span></span></a>
          </OverlayTrigger>
        }
        <>
          {rowData.statut == false ?

            <a onClick={() => handleDelete(rowData.id_ob_op)} className="badge bg-danger p-2" style={{ borderRadius: 30, cursor: 'pointer' }}>
              A respecter
              <span></span>
            </a>
            :

            <Badge bg={"success"}>
              Respectée</Badge>
          }

        </>


        <a onClick={() => handleShow3(rowData.id_ob_op)} className="badge bg-secondary p-2 ml-2" style={{ borderRadius: 30, cursor: 'pointer' }}>
          Ajouter Pièces
          <span></span>
        </a>
        {
          rowData.pieces_obs_obligations
            ?.length > 0 &&
          <a onClick={() => handleShow4(rowData.pieces_obs_obligations
          )} className="badge bg-warning p-2 ml-2" style={{ borderRadius: 30, cursor: 'pointer' }}>
            Voir pièces
            <span></span>
          </a>}

      </div>
    );
  };


  const handleDelete = (rowData) => {
    handleShow1(rowData)
  };

  const rowClassName = (rowData, index) => {
    return index !== pageSuivi.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };
  const datad = (rowData) => {
    const datd = formaterDate(rowData.date_vigueur);
    return datd
  };
  const dataf = (rowData) => {
    const datf = formaterDate(rowData.dat_echeance);
    return datf
  };
  const dataStatus = (rowData) => {

    return (<Badge bg={rowData.jrsRst > 0 ? 'dark' : rowData.jrsRst < 0 && 'danger'}>
      {rowData.jrsRst > 0 ? "Non expiré" : rowData.jrsRst <= 0 && "Expiré"}</Badge>)

  };

  return (

    <div className="card" style={{ width: '100%' }}>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

      <div className="container row mb-5">



        <div className="col">
          <Form.Label>Type d'opérateur :</Form.Label>
          <Form.Select aria-label="Default select example"
            onChange={(v) => chargeTypeCat(v.target.value)}
          >
            <option value="">Tout</option>
            {
              typeOperateur.map((item) => (
                <option value={item.id_type_op}>{item.lib_type_op}</option>
              ))
            }
          </Form.Select>
        </div>
        <div className="col">
          <Form.Label >Opérateur :</Form.Label>


          <Form.Select aria-label="Default select example"
            onChange={(v) => checkOperateur(v.target.value)}
          >


            {pageOperateur.length > 0 &&
              pageOperateur.map((item) => (
                <option value={item.id_operateur
                }>{item.nom_operateur
                  }</option>
              ))
            }

          </Form.Select>
        </div>

        <div className="col">



        </div>


      </div>

      <DataTable
        ref={dt}
        value={pageSuivi}
        header={header}
        tableStyle={{ minWidth: '100%', fontSize: 12 }}
        globalFilter={globalFilter}
        emptyMessage="Pas de résultat."
        paginator
        rows={10}
        rowClassName={rowClassName}
      >
        {cols.map((col, index) => (
          <Column key={index} field={col.field} header={col.header} sortable={col.sortable}
            body={

              col.field === 'actions' ? actionBodyTemplate : col.field === 'datd' ? datad : col.field === 'datf' ? dataf : col.field === 'status' ? dataStatus : null

            }

          />
        ))}
      </DataTable>

      {/* ----------------Modal commentaire gestion obligations----------- */}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Détail du suivi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <table class="table table-bordered border-primary">
              <tr>
                <td class="border col-7"><span className='libel_info_suivi'>Libellé : </span></td>
                <td class="border col-5">{affSuivi?.lib_obligation_reg}</td>
              </tr>
              <tr>
                <td class="border col-7">
                  <span className='libel_info_suivi'>Date d'autorisation :</span>

                </td>
                <td class="border col-5">{affSuivi?.date_vigueur && formaterDate(affSuivi?.date_vigueur)}</td>
              </tr>
              <tr>
                <td class="border col-7"><span className='libel_info_suivi'>Date d'échéance : </span></td>
                <td class="border col-5">{affSuivi?.dat_echeance && formaterDate(affSuivi?.dat_echeance)}</td>
              </tr>
              <tr>
                <td class="border col-7"><span className='libel_info_suivi'>Type d'opérateur : </span></td>
                <td class="border col-5">{affSuivi?.lib_type_op}</td>
              </tr>
              <tr>
                <td class="border col-7"><span className='libel_info_suivi'>Opérateur : </span></td>
                <td class="border col-5">{affSuivi?.operateur}</td>
              </tr>

            </table>




          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>

        </Modal.Footer>
      </Modal>
      {/* ----------------Modal commentaire gestion obligations----------- */}
      <Modal show={show2} onHide={handleClose2} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Modification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <table class="table table-bordered border-primary">
              <tr>
                <td class="border col-7"><span className='libel_info_suivi'>Libellé </span> </td>

              </tr>
              <tr>
                <td class="border col-7">
                  <span className='libel_info_suivi'>Date d'autorisation</span>

                </td>
                <td class="border col-5">
                  {/* {affSuivi?.date_vigueur && formaterDate(affSuivi?.date_vigueur)} */}
                  <Form.Control
                    type="date"
                    value={affSuivi?.date_vigueur && formatDate(affSuivi?.date_vigueur)}
                    // value={affSuivi?.date_vigueur && formaterDate(affSuivi?.date_vigueur)}
                    onChange={(v) => handleEditDate(v.target.value)}
                  // min={formatDate(new Date())}  // La date minimale est aujourd'hui
                  />
                </td>

              </tr>

            </table>




          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="secondary" onClick={handleClose2}>
            Fermer
          </Btn>
          <Btn variant="success" onClick={() => updateDate()}>
            Valider
          </Btn>

        </Modal.Footer>
      </Modal>
      {/* ----------------Modal Ajout fichierss----------- */}
      <Modal show={show3} onHide={handleClose3} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter des pièces jointes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">

            <Form.Control
              type="file"
              onChange={handleFileChange}
              multiple
            />



          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="secondary" onClick={handleClose3}>
            Fermer
          </Btn>
          <Btn variant="success" onClick={() => sendPieces()}>
            Valider
          </Btn>

        </Modal.Footer>
      </Modal>
      {/* ----------------Liste fichierss----------- */}
      <Modal show={show4} onHide={handleClose4} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Liste des pièces jointes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">

            {
              liste_pieces.length > 0 &&



              <Stack direction="row" spacing={5}>
                {liste_pieces.map((piece, index) => (
                  <a key={index} target="_blank" href={`https://apisymfonypigru.leavesmartconsulting.com/public/${piece.nom_piece}`}>
                    Pièces Jointes suivi {++index}
                  </a>
                ))}
              </Stack>
            }

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="secondary" onClick={handleClose4}>
            Fermer
          </Btn>

        </Modal.Footer>
      </Modal>

    </div>
  )

    // (<Spinner
    //     as="span"
    //     animation="border"
    //     size="sm"
    //     role="status"
    //     aria-hidden="true"
    //   />)
    ;
}
