import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableTempPlainte from "./dataTable/TableTempEmailPlainte";
import Swal from "sweetalert2";
import {
  useGetTempEmailPlainteQuery,
  useCreateTempEmailPlainteMutation,
  useUpdateTempEmailPlainteMutation,
  useDeleteTempEmailPlainteMutation,
} from "../../redux/slice/tempEmailPlainteApiSlice";

import { useGetStatutPlaintesQuery } from "../../redux/apiSlice/plainteApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";

export default function TempEmailPlainte() {
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const { data: statutPlaintes } = useGetStatutPlaintesQuery();

  const init = {
    titre: "",
    content: "",
    id_stat_plt: null,
    type: "0",
  };
  const [form, setForm] = useState({ ...init });

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const { data: tempPlainte = [], isLoading: loadTempPlainte } =
    useGetTempEmailPlainteQuery();
  const [createData, { isLoading: loadCreate }] =
    useCreateTempEmailPlainteMutation();
  const [updateData, { isLoading: loadUpdate }] =
    useUpdateTempEmailPlainteMutation();
  const [deleteData, { isLoading: loadDelete }] =
    useDeleteTempEmailPlainteMutation();
    const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const changeForm = (field, value) => setForm({ ...form, [field]: value });

  const save = async () => {
    try {
      if (form?.id_template) {
        await updateData(form).unwrap();
        setForm({ ...init });
        Swal.fire({
          title: "Félicitations!",
          text: "Modification réussie ",
          icon: "success",
        });
      } else {
        await createData(form).unwrap();
        setForm({ ...init });
        Swal.fire({
          title: "Félicitations!",
          text: "Template crée!",
          icon: "success",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.data.errors[0].message,
        footer: '<a href="#">essyer une autre?</a>',
      });
    }
  };

  const deleteTempPlainte = async (id) => {
    try {
      // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)

      // Affichez une confirmation à l'utilisateur avant la suppression réelle (en utilisant une bibliothèque comme Swal)
      const confirmResult = await Swal.fire({
        title: "Êtes-vous sûr?",
        text: "De vouloir supprimer!",
        icon: "danger",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
      });

      if (confirmResult.isConfirmed) {
        // Supprimez réellement la catégorie
        const retour = await deleteData(id).unwrap();

        // Affichez une notification de succès
        Swal.fire({
          title: "Template de Notification!",
          text: "Template  supprimé!",
          icon: "success",
        });
      }
    } catch (error) {
      // console.error("Erreur lors de la suppression :", error);

      // Affichez une notification d'erreur
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.data.message,
        // footer: '<a href="#">Essayer une autre fois?</a>'
      });
    } finally {
      // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)
    }
  };

  switchSkin(skin);
  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de creation de template plainte"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  } 
  const user = useSelector(getUserInfo);
  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard-obligation-reglmt">
                  Tableau de Bord
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                  Template Email/SMS Plaintes
              </li>
            </ol>
            <h4 className="main-title mb-0">Gestion des Templates </h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">
              Liste des Templates Emails/SMS Plaintes
            </span>

            <hr style={{ width: "100%" }} />

            <TableTempPlainte
              deleteTempPlainte={deleteTempPlainte}
              tempPlainte={tempPlainte}
              setForm={setForm}
            />
          </div>

          <div className="second_container">
            <span className="titre_cat">Nouveau Template de Notification</span>

            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Titre :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Titre"
                      onChange={(e) => changeForm("titre", e.target.value)}
                      value={form.titre}
                    />
                  </div>
                </div>
                <br></br>
                <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Contenu :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Contenu"
                      value={form.content}
                      onChange={(e) =>
                        changeForm("content", e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
                <br></br>

                <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Statut Concerné :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Select
                      value={form.id_stat_plt}
                      onChange={(e) =>
                        changeForm("id_stat_plt", parseInt(e.target.value))
                      }
                    >
                      <option value="">Choisir</option>
                      {statutPlaintes?.map((stat, key) => (
                        <option value={stat.id_stat_plt}>{stat.libelle}</option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <br></br>

                <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                    Type de Notif :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Select
                      value={form.type}
                      onChange={(e) =>
                        changeForm("type", parseInt(e.target.value))
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="0">EMAIL</option>
                      <option value="1">SMS </option>
                     
                    </Form.Select>
                  </div>
                </div>

                

                <div className="container_btn">
                  <Button
                    variant="primary"
                    onClick={() => setForm({ ...init })}
                  >
                    Annuler
                  </Button>

                  <Button variant="success" onClick={() => save()}>
                    Enregister
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
