import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableListePlaintes from "./dataTables/TableListePlaintes";
import StepsOfComplain from "./StepsOfComplain";
import { getUserInfo } from "../../redux/slice/userSlice";
import Spinner from "react-bootstrap/Spinner";
import ComplementInformation from "./ComplementInformation";
import ResultatDesInvestigations from "./ResultatDesInvestigations";
import SoumettreRapport from "./SoumettreRapport";
import InstruirePlainte from "./InstruirePlainte";
import MotifDeRupture from "./MotifDeRefus";
import TableDetails from "./TableDetails.js";

import RapportViewer from "./RapportViewer";
import Verdict from "./Verdicts/Verdicts";
import AvisConseil from "./Avis_conseil/AvisConseil.js";
import AvisSatisfaction from "./Avis_conseil/AvisSatisfaction.js";
import Completude from "./Completude/Completude.js";
import { useSelector, useDispatch } from "react-redux";


import {
  useGetServicesQuery,
  useCreateTypePlainteMutation,
  useGetPlaintesQuery,
  useGetStatutPlaintesQuery,
  useGetTypePlaintesQuery,
  useUpdatePlainteStatutMutation,
  useCreateObsPlainteMutation,
  useUpdatePlainteMutation,
  useGetObsPlaintesQuery,
  useGetResultatObsQuery,
} from "../../redux/apiSlice/plainteApiSlice.js";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice.js";
import Historique from "./Historique/Historique";
import Observation from "./Observation/Observation";

export default function ListePlaintes() {
  const [updatePlainte, { isLoading: loadUpdatePlainte }] =
    useUpdatePlainteMutation();

  const [shouldIDisplay, setshouldIDisplay] = useState(false);
  const [shouldIDisplay2, setshouldIDisplay2] = useState(false);
  const [shouldIDisplay3, setshouldIDisplay3] = useState(false);
  const [shouldIDisplay4, setshouldIDisplay4] = useState(false);
  const [shouldIDisplay5, setshouldIDisplay5] = useState(false);
  const [shouldIDisplay6, setshouldIDisplay6] = useState(false);
  const [seeFonction, setSeeFonction] = useState(true);

  const [resultatDesInvestigations, setresultatDesInvestigations] =
    useState(false);
  const {
    data: plaintes,
    refetch: refetchPlaintes,
    isLoading: loadPlaintes,
    isSuccess: isSuccessPlaintes,
  } = useGetPlaintesQuery();
  const { data: typePlaintes = [], isLoading: loadTypePlaintes } =
    useGetTypePlaintesQuery();
  const { data: statutPlaintes, isLoading: loadStatutPlainte } =
    useGetStatutPlaintesQuery();
  const { data: operateurs = [], isLoading: loadOperateur } =
    useGetOperateursQuery();
    
 
  const [createTypePlainte, { isLoading: loadCreateTypePlainte }] =
    useCreateTypePlainteMutation();
    const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [mycomplaint, setComplaint] = useState();
  const handleShow = (complaint) => {
    setComplaint(complaint);
    setShow(true);
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  const createLog = async ()=>{
    try {
      await createLogPage({content: "Page de détails de plainte"}).unwrap();  
  } catch (error) {
   
    console.log('error', error)
  }
  } 
  const connectVerify = useSelector(getUserInfo);

  useEffect(() => {
    if (!connectVerify?.id) {
      window.location.href = "/";
    }
    createLog()

    refetchPlaintes();
    if (localStorage.getItem("token") === "") {
      window.location.href = "/";
    }
   
    if (connectVerify?.profil == "Administrateur" || connectVerify?.profil =="Super Administrateur") {
      setSeeFonction(true)
    } else {
      setSeeFonction(false)
    }
  }, []);

  useEffect(() => {
    if (isSuccessPlaintes) {
      const selectedItems = plaintes;
    
      localStorage.setItem(
        "plaintes",
        JSON.stringify({ liste: selectedItems })
      );
    }
  }, [isSuccessPlaintes, plaintes]);

  switchSkin(skin);

  
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  if (loadPlaintes) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // 100% of the viewport height
        }}
      >
        <Spinner
          as="span"
          animation="border"
          size="md"
          role="status"
          aria-hidden="true"
        />
      </div>
    );
  }

  if (!plaintes) {
    return <div>Error fetching data</div>;
  }

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Liste des plaintes
              </li>
            </ol>
            <h4 className="main-title mb-0">Liste des plaintes</h4>
          </div>
        </div>

        <div>
          <TableListePlaintes
            handleclose={handleClose}
            handleshow={handleShow}
            plaintes={plaintes}
          />
        </div>
        {/* ----------------Modal de détails----------- */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails Plainte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableDetails mycomplaint={mycomplaint} />
            <Observation plainte={mycomplaint} />
            <Completude plainte={mycomplaint} />

            <Verdict plainte={mycomplaint} />

            <br></br>
            <AvisConseil plainte={mycomplaint} />
            <br></br>

            <AvisSatisfaction plainte={mycomplaint} />
            <br></br>
            <Historique plainte={mycomplaint} />
          
          </Modal.Body>

         { seeFonction && <Modal.Footer>
            <StepsOfComplain
              motifDeRefus={() => {
                setshouldIDisplay6(true);
                handleClose();
              }}
              instruirePlainte={() => {
                setshouldIDisplay5(true);
                handleClose();
              }}
              soumettreRapport={() => {
                setshouldIDisplay4(true);
                handleClose();
              }}
              Genererrapport={() => {
                setshouldIDisplay3(true);
                handleClose();
              }}
              setTheshouldIDisplay={() => {
                setshouldIDisplay(true);
                handleClose();
              }}
              complainStatus={mycomplaint?.id_stat_plt}
              complainId={mycomplaint?.id_plt}
              resultatDesInvestigations={() => {
                setshouldIDisplay2(true);
                handleClose();
              }}
            />
          </Modal.Footer>}
        </Modal>

        {shouldIDisplay && (
          <ComplementInformation
            display={shouldIDisplay}
            handleClose={() => {
              setshouldIDisplay(false);
            }}
            id_plt={mycomplaint?.id_plt}
            id_stat_plt={mycomplaint?.id_stat_plt
            }
          />
        )}
        {shouldIDisplay2 && (
          <ResultatDesInvestigations
            display2={shouldIDisplay2}
            handleClose2={() => {
              setshouldIDisplay2(false);
            }}
            id_plt={mycomplaint?.id_plt}
          />
        )}
        {shouldIDisplay3 && (
          <RapportViewer
            display3={shouldIDisplay3}
            handleClose3={() => {
              setshouldIDisplay3(false);
            }}
            mycomplaint={mycomplaint}
          />
        )}
        {shouldIDisplay4 && (
          <SoumettreRapport
            display4={shouldIDisplay4}
            handleClose4={() => {
              setshouldIDisplay4(false);
            }}
            id_plt={mycomplaint?.id_plt}
          />
        )}
        {shouldIDisplay5 && (
          <InstruirePlainte
            display5={shouldIDisplay5}
            handleClose5={() => {
              setshouldIDisplay5(false);
            }}
            id_plt={mycomplaint?.id_plt}
            infOp={mycomplaint?.Operateur}
          />
        )}
        {shouldIDisplay6 && (
          <MotifDeRupture
            display6={shouldIDisplay6}
            handleClose6={() => {
              setshouldIDisplay6(false);
            }}
            id_plt={mycomplaint?.id_plt}
          />
        )}

        <Footer />
      </div>
    </React.Fragment>
  );
}
