import React, {useState} from 'react'
import { Button as Btn, Form } from "react-bootstrap";
import { useGetClientsQuery, useCreateClientsMutation,  useUpdateClientsMutation,
    useDeleteClientsMutation, } from "../../redux/apiSlice/numerotationApiSlice";
    import Swal from "sweetalert2";

function AjoutClientModal({handleAjoutClient}) {

    const init = {
        nom_clt: '',
        ifu_clt: '',
        tel_clt: '',
        email_clt: '',
        adresse_clt: '',
       
    }
    
    const [form, setForm] = useState({...init})

    const [createData] = useCreateClientsMutation()
    
  const checkEmail = (email) => {

    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email)
  }
  
    const changeForm = (field, value) => setForm({...form, [field]: value})

  const save = async() => {

    const checkMail = checkEmail(form.email_clt)


    if (checkMail) {
      try {
        handleAjoutClient()
            await createData(form).unwrap()
            setForm({...init})
            Swal.fire({
              title: "SUSSES!",
              text: "Action réussie",
              icon: "success"
            });
    
     
        
    } catch (error) {
  
        Swal.fire({
          icon: "ERREUR",
          title: "Oops...",
          text: error.data.errors[0].message,
        });
    }
    } else {
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: "format email non valide",
      });
    }
    
}
  return (
    <div className="container  row">
           
<div className="sous_container_form">

 

<div className="container_label">
<Form.Label htmlFor="exampleFormControlInput1">
           Nom :
          </Form.Label>
</div>
<div style={{width:'70%'}}>
<Form.Control
            type="text"
            id="exampleFormControlInput1"
            placeholder="Nom"
            value={form.nom_clt}
            onChange={e => changeForm('nom_clt', e.target.value)}
            
          />
</div>
         
</div>
<div className="sous_container_form mt-3">



<div className="container_label">
<Form.Label htmlFor="exampleFormControlInput1">
           IFU :
          </Form.Label>
</div>
<div style={{width:'70%'}}>
<Form.Control
            type="number"
            id="exampleFormControlInput1"
            placeholder="votre IFU"
            value={form.ifu_clt}
            onChange={e => {
          
              if(e.target.value < 0){
                
              }else{
                changeForm('ifu_clt', e.target.value)
              }
            }}
            
          />
</div>
         
</div>
<div className="sous_container_form mt-3">



<div className="container_label">
<Form.Label htmlFor="exampleFormControlInput1">
           Téléphone :
          </Form.Label>
</div>
<div style={{width:'70%'}}>
<Form.Control
            type="number"
            id="exampleFormControlInput1"
            placeholder="teléphone"
            value={form.tel_clt}
             onChange={e => {
              if(e.target.value < 0){
               
              }else{
                changeForm('tel_clt', e.target.value)
              }
              }}
            
          />
</div>
         
</div>


<div className="sous_container_form mt-3">



<div className="container_label">
<Form.Label htmlFor="exampleFormControlInput1">
           Email :
          </Form.Label>
</div>
<div style={{width:'70%'}}>
<Form.Control
            type="text"
            id="exampleFormControlInput1"
            placeholder="email"
            value={form.email_clt}
            onChange={e => changeForm('email_clt', e.target.value)}
            
          />
</div>
         
</div>

<div className="sous_container_form mt-3">

<div className="container_label">
<Form.Label htmlFor="exampleFormControlInput1">
           Adresse :
          </Form.Label>
</div>
<div style={{width:'70%'}}>
        <Form.Control
          onChange={e => changeForm('adresse_clt', e.target.value)}
          as="textarea"
          value={form.adresse_clt}
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="Entrer votre adresse..."
        ></Form.Control>
</div>
         
</div>

<div className="container_btn">
<Btn  variant="primary" onClick={()=>handleAjoutClient()}>
                Annuler
              </Btn>

              <Btn  variant="danger" onClick={()=>save()}>
                Enregister
              </Btn>
</div>
          </div>
  )
}

export default AjoutClientModal
