import { apiSlice } from "../api/apiSlice"

export const categorieObligationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategorieObligations: builder.query({
            query: () => ({
                url: '/obligation/cat_obligation',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'CategorieObligations', id: 'LIST' },
                    ...result.map(item => ({ type: 'CategorieObligations', id: item.id_cat_obligation_reg }))
                ] : ['CategorieObligations']
            }
        }),

        createCategorieObligation: builder.mutation({
            query: body => ({
                url: '/obligation/cat_obligation/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['CategorieObligations']
        }),

        updateCategorieObligation: builder.mutation({
            query: body => ({
                url: `/obligation/cat_obligation/update/${body.id_cat_obligation_reg}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['CategorieObligations']
        }),

       
        deleteCategorieObligation: builder.mutation({
           
            query: (id) => ({
              url: `obligation/cat_obligation/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['CategorieObligations'],
          }),
       
    })
})

export const {
    useGetCategorieObligationsQuery,
    useCreateCategorieObligationMutation,
    useUpdateCategorieObligationMutation,
    useDeleteCategorieObligationMutation,

} = categorieObligationApiSlice