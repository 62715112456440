import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { useGetAvisConseilPlainteQuery } from "../../../redux/apiSlice/plainteApiSlice";
import { useGetVerdicteParPlainteQuery, useGetVerdictePiecesQuery } from "../../../redux/apiSlice/plainteApiSlice";
import { Stack } from 'rsuite'

const AvisConseil = (props) => {
  const { plainte } = props;

  const { data: avis = [], isLoading } = useGetAvisConseilPlainteQuery(
    { id_plt: plainte.id_plt }
  );
  // console.log('plainte.id_pltavis', plainte.id_plt)

  const [verdictInstructeur, setVerdictInstructeur] = useState([]);
  const [pieces, setPieces] = useState([]);

  const { data: verdiscts = [], isLoading: loadVerdict } = useGetVerdicteParPlainteQuery(
    { id_plt: plainte.id_plt }
  );

  const { data: verdiscts_pieces = [], isLoading: loadVerdictPieces } = useGetVerdictePiecesQuery(
    { id_plt: plainte.id_plt }
  );

  const soustitreStyle = {
    fontSize: '1.4em', // Taille de la police
    fontWeight: 'bolder', // Gras
    // color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const tabletitreStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'bolder', // Gras
    // color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };


  const contenuStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'normal', // Gras
    // color: '#000', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const commentStyle = {
    fontSize: '1.2em', // Taille de la police
    fontWeight: 'normal', // Gras
    // color: '#000', // Couleur du texte (par exemple, bleu Bootstrap)
    paddingLeft: '35px'
    // Ajoutez d'autres propriétés de style au besoin
  };

  const formateDatePlainte = (laDate) => {
    const dateObject = new Date(laDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
    return formattedDate;
  };

  useEffect(() => {

  }, [avis]);

  useEffect(() => {
    if (verdiscts) {
      const tabFilter = verdiscts.filter((item) => (item.type_verdict == 1))

      setVerdictInstructeur(tabFilter)
    }
  }, [verdiscts]);

  useEffect(() => {
    if (verdiscts_pieces) {
      const tabFilter = verdiscts_pieces.filter((item) => (item.type_verdict == 1))

      setPieces(tabFilter)
    }

  }, [verdiscts_pieces]);


  return isLoading || loadVerdict || loadVerdictPieces ?
    (
      <div class="card">
        <Button variant="primary" disabled style={{ height: 200 }}>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
      </div>
    )
    :
    (
      verdictInstructeur.length > 0 && (
        <>
          <h4 style={soustitreStyle}>&nbsp; Action du comité</h4>
          <div class="card ml-5">
            <div class="card-body">
              <div className="row">
                <div class="table-responsive ">
                  <table class="table mb-0 table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={tabletitreStyle} scope="col-1">#</th>
                        <th style={tabletitreStyle} scope="col-4">Décision</th>
                        {/* <th style={tabletitreStyle} scope="col-4">Commentaire</th> */}
                        <th style={tabletitreStyle} scope="col-3">Date de décision</th>
                      </tr>
                    </thead>

                    <tbody>
                      {verdictInstructeur.map((verdict, key) => (
                        <tr key={key}>
                          <td class=" col-1" style={contenuStyle} scope="row">{++key}</td>
                          <td class=" col-4" style={contenuStyle}>{verdict.Decision_plainte?.lib_dec}</td>
                          {/* <td class=" col-4" style={contenuStyle}>{verdict.commentaire == null ? "-": verdict.commentaire}</td> */}
                          <td class=" col-3" style={contenuStyle}>{moment(verdict.createdAt).format("DD/MM/YYYY")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                {pieces.length > 0 &&
                  <div className='mt-3 mb-4'>
                    <h6 style={soustitreStyle}>&nbsp;</h6>
                    <div style={commentStyle} />
                    <div style={commentStyle} className="col-lg-12 col-md-12">
                      <h6 style={{ ...soustitreStyle, fontSize: '1em' }}>Pièces</h6>
                      <Stack direction="row" spacing={5}>
                        {pieces.map((piece, index) => (
                          <a key={index} target="_blank" href={`https://apisymfonypigru.leavesmartconsulting.com/public/${piece.nom_piece}`}>
                            Pièces Jointes {++index}
                          </a>
                        ))}
                      </Stack>
                    </div>

                  </div>
                }
              </div>

              {avis.length > 0 && <div className="row">
                <div class="table-responsive ">
                  <table class="table mb-0 table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={tabletitreStyle} scope="col-1">#</th>
                        <th style={tabletitreStyle} scope="col-4">Avis</th>
                        <th style={tabletitreStyle} scope="col-3">Date</th>

                      </tr>
                    </thead>

                    <tbody>
                      {avis.map((avis, key) => (
                        <tr key={key}>
                          <td class=" col-1" style={contenuStyle} scope="row">{++key}</td>
                          <td class=" col-4" style={contenuStyle}>{avis.content}</td>
                          <td class=" col-3" style={contenuStyle}>{moment(avis.createdAt).format("DD/MM/YYYY")}</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>}
            </div>
          </div>


        </>
      )
    );
};

export default AvisConseil;
