import React, { useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.jpg";
import Iso from "../assets/img/iso.jpg";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useUpdateUserMutation } from "../redux/apiSlice/userApiSlice";
import { getUserInfo, setUser } from "../redux/slice/userSlice";

export default function ModifierPwd() {
  const user = useSelector(getUserInfo);
  const conserverUser = user;
  const dispatch = useDispatch();
  const initUser = {
    nom_user: user?.name,
    email_user: user?.email,
    pwd_user: "",
    new_pwd: "",
  };

  const navigate = useNavigate();

  const [creerUser, setCreerUser] = useState({ ...initUser });
  const [updateUser, { isLoading: loadUpdateUser }] = useUpdateUserMutation();

  const isRequired = creerUser.pwd_user === "" || creerUser.new_pwd === "";

  const [errorMsg, setErrorMsg] = useState({
    pwd_user: "",
    new_pwd: "",
  });

  const changeData = (field, value) => {
    setCreerUser({ ...creerUser, [field]: value });
    if (field === "pwd_user" && value === "") {
      setErrorMsg((prevErrors) => ({
        ...prevErrors,
        pwd_user: "Veillez saisir votre mot de passe",
      }));
    } else if (field === "new_pwd" && value === "") {
      setErrorMsg((prevErrors) => ({
        ...prevErrors,
        new_pwd: "Veillez saisir votre nouveau mot de passe",
      }));
    } else {
      setErrorMsg((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
  };

  const saveUser = async () => {
    try {
      if (creerUser.pwd_user !== creerUser.new_pwd) {
        toast.error("Les mots de passe doivent correspondre !!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        await updateUser({
          ...creerUser,
          id_user: user?.id,
        }).unwrap();
        dispatch(
          setUser({
            name: creerUser?.nom_user,
            organisme: conserverUser?.organisme,
            profil: conserverUser?.profil,
            id: conserverUser?.id,
            token: conserverUser?.token,
            email: creerUser?.email_user,
          })
        );
        toast.success("Profil modifié", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCreerUser({ ...initUser });
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error("Une erreur est subvenue lors de la création", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log("error", error);
    }
  };

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="d-flex flex-row justify-content-center">
            <Image
              src={Iso}
              alt=""
              style={{ height: "100px", width: "160px" }}
            />
          </Link>
          <Card.Title className="d-flex flex-row justify-content-center mt-4">
            Modifier votre profil
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <div className="mb-4">
              <Form.Label htmlFor="exampleFormControlInput1">
                Nom utilisateur :
              </Form.Label>
              <Form.Control
                type="text"
                id="exampleFormControlInput1"
                placeholder="Jules BOSSOU"
                value={creerUser.nom_user}
                disabled
                onChange={(e) => changeData("nom_user", e.target.value)}
              />
            </div>
          </Form>

          <Form>
            <div className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="admin34@gmail.com"
                value={creerUser.email_user}
                disabled
                onChange={(e) =>
                  changeData("email_user", e.target.value.trim())
                }
              />
            </div>
          </Form>
          <Form>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password{" "}
              </Form.Label>
              <Form.Control
                // type={visible ? "text" : "password"}
                type="password"
                placeholder="**********"
                value={creerUser.pwd_user}
                onChange={(e) => changeData("pwd_user", e.target.value.trim())}
              />
              {errorMsg.pwd_user && (
                <span className="text-danger">{errorMsg.pwd_user}</span>
              )}
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Confirmation{" "}
              </Form.Label>
              <Form.Control
                // type={visible ? "text" : "password"}
                type="password"
                placeholder="**********"
                value={creerUser.new_pwd}
                onChange={(e) => changeData("new_pwd", e.target.value.trim())}
              />
              {errorMsg.new_pwd && (
                <span className="text-danger">{errorMsg.new_pwd}</span>
              )}
            </div>
          </Form>

          <Button
            variant="primary"
            className="btn-sign"
            disabled={isRequired || loadUpdateUser}
            onClick={() => saveUser()}
          >
            {loadUpdateUser ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Se connecter</span>
            )}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
