import { apiSlice } from "../api/apiSlice"

export const typeOperateurApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTypeOperateurs: builder.query({
            query: () => ({
                url: '/operateur/type_operateur',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'TypeOperateur', id: 'LIST' },
                    ...result.map(item => ({ type: 'TypeOperateur', id: item.id_type_op }))
                ] : ['TypeOperateur']
            }
        }),

        createTypeOperateur: builder.mutation({
            query: body => ({
                url: '/operateur/type_operateur/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['TypeOperateur']
        }),

        updateTypeOperateur: builder.mutation({
            query: body => ({
                url: `/operateur/type_operateur/update/${body.id_type_op}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['TypeOperateur']
        }),
        deleteTypeOperateur: builder.mutation({
           
            query: (id) => ({
              url: `/operateur/type_operateur/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['TypeOperateur'],
          }),
    })
})

export const {
    useGetTypeOperateursQuery,
    useCreateTypeOperateurMutation,
    useUpdateTypeOperateurMutation,
    useDeleteTypeOperateurMutation,
} = typeOperateurApiSlice