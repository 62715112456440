import { apiSlice } from "../api/apiSlice";

export const prestationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrestation: builder.query({
      query: () => ({
        url: "/prestation_auth",
        method: "GET",
      }),
    }),
    createPrestation: builder.mutation({
      query: (body) => ({
        url: "/prestation/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Prestation"],
    }),
    updatePrestation: builder.mutation({
      query: (body) => ({
        url: `/prestation/update/${body.id_prest }`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Prestation"],
    }),
    deletePrestation: builder.mutation({
      query: (body) => ({
        url: `/prestation/del/${body.id_prest }`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Prestation"],
    }),
  }),
});

export const {
  useGetPrestationQuery,
  useCreatePrestationMutation,
  useUpdatePrestationMutation,
  useDeletePrestationMutation,
} = prestationApiSlice;
