import { apiSlice } from "../api/apiSlice"

export const paramSMTPApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getParamSMTP: builder.query({
            query: () => ({
                url: '/settings/smtp',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'ParamSMTP', id: 'LIST' },
                    ...result.map(item => ({ type: 'ParamSMTP', id: item.id }))
                ] : ['ParamSMTP']
            }
        }),

        // createParamSMTP: builder.mutation({
        //     query: body => ({
        //         url: '/settings/smtp/create',
        //         method: 'POST',
        //         body
        //     }),
        //     invalidatesTags: ['ParamSMTP']
        // }),

        updateParamSMTP: builder.mutation({
            query: body => ({
                url: `/settings/smtp/update`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['ParamSMTP']
        }),

       
        // deleteParamSMTP: builder.mutation({
           
        //     query: (id) => ({
        //       url: `/settings/smtp/del/${id}`,
        //       method: 'DELETE',
        //     }),
        //     invalidatesTags: ['ParamSMTP'],
        //   }),
       
    })
})

export const {
    useGetParamSMTPQuery,
    // useCreateParamSMTPMutation,
    useUpdateParamSMTPMutation,
    // useDeleteParamSMTPMutation,

} = paramSMTPApiSlice