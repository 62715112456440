import { apiSlice } from "../api/apiSlice"

export const secteurObligationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSecteurObligations: builder.query({
            query: () => ({
                url: '/obligation/secteur_obligation',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'SecteurObligations', id: 'LIST' },
                    ...result.map(item => ({ type: 'SecteurObligations', id: item.id_secteur_obligation }))
                ] : ['SecteurObligations']
            }
        }),

        createSecteurObligation: builder.mutation({
            query: body => ({
                url: '/obligation/secteur_obligation/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['SecteurObligations']
        }),

        updateSecteurObligation: builder.mutation({
            query: body => ({
                url: `/obligation/secteur_obligation/update/${body.id_secteur_obligation}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['SecteurObligations']
        }),
        deleteSecteurObligation: builder.mutation({
           
            query: (id) => ({
              url: `/obligation/secteur_obligation/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['SecteurObligations'],
          }),
    })
})

export const {
    useGetSecteurObligationsQuery,
    useCreateSecteurObligationMutation,
    useUpdateSecteurObligationMutation,
    useDeleteSecteurObligationMutation,
} = secteurObligationApiSlice